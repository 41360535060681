import React from 'react';

const GrievanceOptions = (props) => {
  const options = [
    { text: 'eKYC Related', value: 'type1' },
    { text: 'Schemes Related', value: 'type2' },
    { text: 'Portal Related', value: 'type3' },
    { text: 'Mobile App Related', value: 'type4' },
    { text: 'Others', value: 'type5' },
  ];

  const handleClick = (option) => {
    props.actionProvider.handleOptionSelection(option);
  };

  return (
    <div style={styles.optionsContainer}>
      {options.map((option) => (
        <button
          key={option.value}
          style={styles.optionButton}
          onClick={() => handleClick(option.value)}
        >
          {option.text}
        </button>
      ))}
    </div>
  );
};

const styles = {
  optionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  optionButton: {
    backgroundColor: '#007bff',
    color: 'white',
    padding: '10px',
    margin: '5px 0',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    width: '100%',
    textAlign: 'left',
  },
};

export default GrievanceOptions;
