import { useState, useEffect } from "react";
// react-router-dom components
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
// @mui material components
import Card from "@mui/material/Card";
//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { useLanguage } from '../../../LanguageContext';
import { translations } from "../translations";
import CarouselComponent from '../styledcarousel';
import axios from "axios";
import { Apiurl } from "../../../config";
import Header from '../header';
import FooterComponent from '../footer';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CryptoJS from 'crypto-js';
import { IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
const SignInContainer = styled.div`
  position: relative;
  z-index: 1;
  overflow-x: hidden;
  max-width: 100vw;
`;
function encodeToBase64(plainText, secretKey) {
  try {
    const keyBytes = CryptoJS.SHA256(secretKey).toString(CryptoJS.enc.Hex).slice(0, 32);
    const key = CryptoJS.enc.Hex.parse(keyBytes); 
    const iv = CryptoJS.enc.Hex.parse(keyBytes); 
    const encrypted = CryptoJS.AES.encrypt(plainText, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
 
    return encrypted.toString();
  } catch (error) {
    console.error('Encryption error:', error);
    return "";
  }
}
function decodeFromBase64(encryptedText, secretKey) {

  // Create a 16-byte key
  const keyBytes = CryptoJS.SHA256(secretKey).toString(CryptoJS.enc.Hex).slice(0, 32); // Use the first 16 bytes of the hash
  const key = CryptoJS.enc.Hex.parse(keyBytes);
  const iv = CryptoJS.enc.Hex.parse(keyBytes); // Use the same bytes for IV

  const decrypted = CryptoJS.AES.decrypt(encryptedText, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
  return decrypted.toString(CryptoJS.enc.Utf8);
}
function generateCaptcha() {
  let num1, num2, operation;

  do {
    num1 = generateRandomNumber(0, 20);
    num2 = generateRandomNumber(0, 10);
    operation = '-';
  } while (num1 < num2);
  if (Math.random() < 0.5) {
    operation = '+';
  }

  const expression = `${num1} ${operation} ${num2}`;
  const result = operation === '+' ? num1 + num2 : num1 - num2;

  return { expression, result };
}

// Function to generate random number between min and max (inclusive)
function generateRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const { language } = useLanguage();
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [selectedUsername, setSelectedUsername] = useState('');
  const [selectedUserPassword, setSelectedUserPassword] = useState('');
  const [captcha, setCaptcha] = useState(generateCaptcha());
  const [captchaInput, setCaptchaInput] = useState('');
  const [captchaError, setCaptchaError] = useState('');
  const [credentialError, setCredentialError] = useState('');
  const [departments, setDepartments] = useState([]);
  const [ip, setIp] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState('');
  const handleTogglePasswordVisibility = () => {
    setShowPassword(prevState => !prevState);
  };
  const handleDepartmentChange = (event) => {
    setSelectedDepartmentId(event.target.value);
  };
  useEffect(() => {
    const fetchIp = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        let ipAddress = response.data.ip;
        let ipWithoutDots = ipAddress.replace(/\./g, '');
        let firstFour = ipWithoutDots.slice(0, 4);
        let lastFour = ipWithoutDots.slice(-4);
        let modifiedIp = firstFour + lastFour;
        setIp(modifiedIp);
      } catch (error) {
        console.error('Error fetching the IP address:', error);
      }
    };

    fetchIp();
  }, []);
  useEffect(() => {
    if (captchaInput && captchaInput !== String(captcha.result)) {
      setCaptchaError(translations[language].captchaError);
    } else {
      setCaptchaError('');
    }
  }, [captchaInput, captcha.result, language]);
  const handleSignIn = async () => {
    if (captchaInput !== String(captcha.result)) {
      setCaptchaError(translations[language].captchaError);
    } else {
      try {
        const currentDate = new Date().toISOString().split('T')[0].replace(/-/g, '');
        const key = currentDate + ip;
        const username = encodeToBase64(selectedUsername, key);
        console.log(decodeFromBase64(username, key));
        const userpassword = encodeToBase64(selectedUserPassword, key);
        let response;
        if (window.location.href.includes("localhost")) {
           response = await axios.post(Apiurl + "login");
        } else {
           response = await axios.post(Apiurl + "login", {
            Username: username,
            Password: userpassword,
            department_id: "2"
          });
        }
        if (response.data.error) {
          console.error("Error:", response.data.error);
          setCredentialError(response.data.error);
        } else {
          localStorage.setItem("USER", response.data.USER);
          localStorage.setItem("TOKEN", response.data.TOKEN);
          navigate('/department/dashboard');
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };
  const regenerateCaptcha = () => {
    setCaptcha(generateCaptcha());
    setCaptchaInput('');
    setCaptchaError('');
  };
  const headers = {
    Authorization: `Bearer`
  };
  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        let response;
        response = await axios.get(Apiurl + "get/departments", { headers });
        
        const departmentData = response.data.data ? response.data.data : response.data;
        if (departmentData) {
          // Transform the data to include both id and name
          const departmentList = Object.entries(departmentData).map(([id, name]) => ({
            id,
            name
          }));
          setDepartments(departmentList);
        } else {
          console.error("Invalid data structure:", departmentData);
        }
      } catch (error) {
        console.error("Error fetching departments data:", error);
      }
    };
    fetchDepartment();
  }, []);
  
  return (
    <>
      <Header />
      <CarouselComponent />
      <SignInContainer>
        <BasicLayout>
          <Card>

            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center"
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                {translations[language].signIn}
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <MDBox component="form" role="form">
                <MDBox mb={2}>
                  <select
                    className="form-control"
                    value={selectedDepartment}
                    onChange={(e) => setSelectedDepartment(e.target.value)}
                  >
                    <option value="">Select Department</option>
                      {departments.map((department) => (
                        <option key={department.id} value={department.id}>
                          {department.name}
                        </option>
                    ))}
                  </select>
                </MDBox>
                {selectedDepartment && (
                  <>
                    <MDBox mb={2}>
                      {selectedDepartment === "e-sevai operator" ? (
                        <input
                          type="text"
                          placeholder="Operator ID"
                          onChange={(e) => setSelectedUsername(e.target.value)}
                          maxLength={20}
                          style={{
                            border: '1px solid blue',
                            padding: '8px',
                            borderRadius: '4px',
                            outline: 'none',
                            width: '100%',
                          }}
                        />
                      ) : (
                        <input
                          type="text"
                          placeholder="Employee ID"
                          onChange={(e) => setSelectedUsername(e.target.value)}
                          maxLength={20}
                          style={{
                            border: '1px solid blue',
                            padding: '8px',
                            borderRadius: '4px',
                            outline: 'none',
                            width: '100%',
                          }}
                        />
                      )}
                    </MDBox>
                    <MDBox mb={2}>
                      <div style={{ position: 'relative', width: '100%' }}>
                        <input
                          type={showPassword ? 'text' : 'password'}
                          placeholder={translations[language].password}
                          style={{
                            border: '1px solid blue',
                            padding: '8px',
                            borderRadius: '4px',
                            outline: 'none',
                            width: '100%',
                            paddingRight: '40px'
                          }}
                          onChange={(e) => setSelectedUserPassword(e.target.value)}
                          maxLength={20}
                        />
                        <IconButton
                          onClick={handleTogglePasswordVisibility}
                          style={{
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            padding: '0'
                          }}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </div>
                    </MDBox>
                    <MDBox>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <label htmlFor="captchaInput"> {/* Use htmlFor instead of for */}
                          {translations[language].captchaLabel}
                        </label>
                        <MDTypography variant="body1" color="text">
                          {captcha.expression} =
                        </MDTypography>
                        <MDInput
                          type="text"
                          value={captchaInput}
                          inputProps={{ maxLength: 2 }}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d*$/.test(value)) { // Only allow numeric input
                              setCaptchaInput(value);
                            }
                          }}
                          error={!!captchaError}
                          style={{ width: '80px' }} // Adjust the width as needed
                        />
                        <p onClick={regenerateCaptcha} style={{ cursor: 'pointer', color: 'blue' }}><AutorenewIcon /></p>
                      </div>
                      {captchaError && (
                        <MDTypography variant="caption" color="error">
                          {captchaError}
                        </MDTypography>
                      )}
                    </MDBox>
                    {credentialError && (
                      <MDTypography variant="caption" color="error">
                        {credentialError}
                      </MDTypography>
                    )}
                    <MDBox mt={4} mb={1}>
                      <MDButton variant="gradient" color="info" fullWidth onClick={handleSignIn}>
                        {translations[language].signInButton}
                      </MDButton>
                    </MDBox>
                  </>
                )}
              </MDBox>
            </MDBox>
          </Card>
        </BasicLayout>
      </SignInContainer>
      <FooterComponent language={language} />
    </>
  );
}

export default Basic;
