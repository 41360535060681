import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { useLanguage } from '../../LanguageContext';
import TNLogo from '../../assets/images/TNLogo.jpeg';
import TNeGALogo from '../../assets/images/TnegaLogo.jpeg';
import { translations } from "./translations";
import { contactUsLink } from "../../config";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon, faPlus, faMinus, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ThemeContext } from '../../DarkThemeContext';
import HomeIcon from '@mui/icons-material/Home';
const HeaderMenu = styled.header`
  width: 100%;
  top: 0;
  left: 0;
  background-color: ${({ darkMode }) => (darkMode ? '#333' : '#fff')};
  z-index: var(--z-fixed);
  display: flex;
  flex-wrap: wrap;
  border-bottom: 0.1rem solid #333;
  padding-bottom: 0.3rem;
  color: ${({ darkMode }) => (darkMode ? '#fff' : '#000')};
`;

const FirstDiv = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: 1rem;
  background: ${({ darkMode }) =>
    darkMode ? 'linear-gradient(to right, #333, #333 50%, #333 70%, #666 90%, #999 100%)'
      : 'linear-gradient(to right, #ffffff, #ffffff 50%, #ffffff 70%, #9ecce7 90%, #0079c1 100%)'};

  @media (max-width: 768px) {
    // width: 100%;
    // justify-content: space-between;
    // margin-left: 0;
    // padding: 0.5rem;
    flex: 1;
  display: flex;
  }
`;

const SecondDiv = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ darkMode }) => (darkMode ? '#666' : '#0079c1')};
  color: ${({ darkMode }) => (darkMode ? '#fff' : '#fff')};

  @media (max-width: 768px) {
    // width: 100%;
    // justify-content: center;
    // padding: 0.5rem;
    flex: 1;
  display: flex;
  }
`;

const ThirdDiv = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: ${({ darkMode }) =>
    darkMode ? 'linear-gradient(to left, #333, #333 50%, #333 80%, #666 90%, #999 100%)'
      : 'linear-gradient(to left, #ffffff, #ffffff 50%, #ffffff 80%, #9ecce7 90%, #0079c1 100%)'};

  @media (max-width: 768px) {
    // width: 100%;
    // justify-content: space-between;
    // padding: 0.5rem;
    flex: 1;
  display: flex;
  }
`;

const Nav = styled.nav`
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: relative;
  height: var(--header-height);
`;

const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.5rem;
  padding: 0;
  margin: 0;
  list-style: none;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    background-color: ${({ darkMode }) => (darkMode ? '#333' : '#fff')};
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    max-height: ${({ showMenu }) => (showMenu ? '500px' : '0')};
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    z-index: 10;
  }
`;

const NavItem = styled.li`
  .nav__link {
    color: ${({ darkMode }) => (darkMode ? '#fff' : 'var(--title-color)')};
    font-weight: var(--font-semi-bold);
    transition: color 0.4s;
    text-decoration: none;
  }
  .nav__link:hover {
    color: var(--first-color);
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 1rem;
    border-bottom: 1px solid #ccc;

    .nav__link {
      color: ${({ darkMode }) => (darkMode ? '#fff' : '#000')};
    }
  }
`;

const ToggleIcon = styled.div`
  cursor: pointer;
`;

const MobileMenuIcon = styled.div`
  display: none;
  cursor: pointer;
  font-size: 1.5rem;
  
  @media (max-width: 768px) {
    display: block;
  }
`;

const Header = () => {
  const { darkMode, toggleDarkMode, increaseFontSize, decreaseFontSize } = useContext(ThemeContext);
  const { language, toggleLanguage } = useLanguage();
  const [showMenu, setShowMenu] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(language);
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const closeMenuOnMobile = () => {
    if (window.innerWidth <= 768) {
      setShowMenu(false);
    }
  };

  const handleLanguageChange = (lang) => {
    setSelectedLanguage(lang);
    toggleLanguage(lang);
    closeMenuOnMobile();
  };

  return (
    <HeaderMenu className="header" darkMode={darkMode}>
      <Nav className="nav container-fluid">
        <FirstDiv darkMode={darkMode}>
          <NavLink to="/home">
            <img src={TNLogo} alt="TN Logo" className="tn-logo" style={{ width: '4.5rem', height: '4.5rem' }} />
          </NavLink>
          <NavLink to="/home" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '1rem', textDecoration: 'none', color: 'inherit' }}>
            <span>{translations[language].tnEga}</span>
            <span>{translations[language].govOfTn}</span>
          </NavLink>
        </FirstDiv>
        <SecondDiv darkMode={darkMode}>
          <h3>{translations[language].knowYourCitizen}</h3>
        </SecondDiv>
        <ThirdDiv darkMode={darkMode}>
          <MobileMenuIcon onClick={toggleMenu}>
            <FontAwesomeIcon icon={showMenu ? faTimes : faBars} />
            <img src={TNeGALogo} alt="TNeGA Logo" className="tnega-logo" style={{ width: '3.125rem', height: '3.125rem', marginLeft: 'auto' }} />
          </MobileMenuIcon>
          <NavMenu className={`nav__menu ${showMenu ? 'show-menu' : ''}`} id="nav-menu" darkMode={darkMode} showMenu={showMenu}>
          <NavItem className="nav__item" darkMode={darkMode}>
              <NavLink to="/home" className="nav__link" onClick={closeMenuOnMobile} title="Home Page">
               <HomeIcon/>
              </NavLink>
            </NavItem>
            <NavItem className="nav__item" darkMode={darkMode}>
              <NavLink to="/" className="nav__link" onClick={closeMenuOnMobile}>
                {translations[language].services}
              </NavLink>
            </NavItem>
            <NavItem className="nav__item" darkMode={darkMode}>
              <a href={contactUsLink} className="nav__link"  target="_blank" onClick={closeMenuOnMobile}>
                {translations[language].contactUs}
              </a>
            </NavItem>

            {!showMenu && (
              <>
                <div>
                  <select value={selectedLanguage} onChange={(e) => handleLanguageChange(e.target.value)}>
                    <option value="en">English</option>
                    <option value="ta">Tamil</option>
                  </select>
                </div>
                <ToggleIcon onClick={toggleDarkMode}>
                  <FontAwesomeIcon icon={darkMode ? faSun : faMoon} />
                </ToggleIcon>
                <ToggleIcon onClick={decreaseFontSize}>
                  <FontAwesomeIcon icon={faMinus} />
                </ToggleIcon>
                <ToggleIcon onClick={increaseFontSize}>
                  <FontAwesomeIcon icon={faPlus} />
                </ToggleIcon>
                <img src={TNeGALogo} alt="TNeGA Logo" className="tnega-logo" style={{ width: '3.125rem', height: '3.125rem', marginLeft: 'auto' }} />
              </>
            )}
          </NavMenu>
        </ThirdDiv>
      </Nav>
    </HeaderMenu>
  );
};


export default Header;
