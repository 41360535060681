import React, { useState } from 'react';
import Chatbot from 'react-chatbot-kit';
import 'react-chatbot-kit/build/main.css';

import config from '../chatbotConfig';
import MessageParser from '../MessageParser';
import ActionProvider from '../ActionProvider';

const ChatbotWrapper = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleChatbot = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div style={styles.wrapper}>
      {!isOpen && (
        <button style={styles.button} onClick={toggleChatbot}>
          Open Chat
        </button>
      )}
      {isOpen && (
        <div style={styles.chatbotContainer}>
          <div style={styles.chatbotHeader}>
            <span style={styles.chatbotTitle}>Chatbot</span>
            <button style={styles.closeButton} onClick={toggleChatbot}>
              &times;
            </button>
          </div>
          <div style={styles.chatbot}>
            <Chatbot config={config} messageParser={MessageParser} actionProvider={ActionProvider} />
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  wrapper: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    zIndex: 1000,
  },
  button: {
    backgroundColor: '#007bff',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  chatbotContainer: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    width: '300px',
    maxHeight: '500px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    backgroundColor: 'white',
    borderRadius: '5px',
  },
  chatbotHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    borderBottom: '1px solid #eee',
    backgroundColor: '#007bff',
    color: 'white',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
  },
  chatbotTitle: {
    margin: 0,
  },
  closeButton: {
    background: 'none',
    border: 'none',
    color: 'white',
    fontSize: '20px',
    cursor: 'pointer',
  },
  chatbot: {
    maxHeight: '440px',
    overflowY: 'auto',
  },
};

export default ChatbotWrapper;
