import React, { useState } from "react";
import styled from "styled-components";
import { useLanguage } from '../LanguageContext';
import { translations } from "../layouts/authentication/translations";
import { Container } from "./styledComponents";
import Header from '../layouts/authentication/header';
import FooterComponent from '../layouts/authentication/footer';
import CryptoJS from 'crypto-js'; // Import CryptoJS for encryption

const MainContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1; /* Grow to fill available space */
  padding: 1rem; /* Add padding for better spacing */
  text-align: center;
`;

function getCurrentDate() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');

  return `${year}${month}${day}`;
}

function encodeToBase64(plainText) {
  const currentDate = getCurrentDate(); 
  const deviceId = "1588afa9d8d1c1e9";  
  const secretKey = `${currentDate}${deviceId}`;

  try {
    const keyBytes = CryptoJS.SHA256(secretKey).toString(CryptoJS.enc.Hex).slice(0, 32);
    const key = CryptoJS.enc.Hex.parse(keyBytes); 
    const iv = CryptoJS.enc.Hex.parse(keyBytes); 
    const encrypted = CryptoJS.AES.encrypt(plainText, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
 
    return encrypted.toString();
  } catch (error) {
    console.error('Encryption error:', error);
    return "";
  }
}

const Accessibility = () => {
  const { language, toggleLanguage } = useLanguage();
 
  const username = encodeToBase64("11087045");
  console.log(username);

  return (
    <>
      <Container>
        <Header />
        <MainContent>
          <div>
            <h1 style={{ textAlign: 'center' }}>{translations[language].accessibilityStatement.title}</h1>
            <p>{translations[language].accessibilityStatement.firstContent}</p>
            <p>{translations[language].accessibilityStatement.secondContent}</p>
            <p>{translations[language].accessibilityStatement.thirdContent}</p>
          </div>
        </MainContent>
        <FooterComponent language={language} />
      </Container>
    </>
  );
};

export default Accessibility;
