import React, { useContext } from 'react';
import styled from 'styled-components';
import { translations } from "./translations";
import { ThemeContext } from '../../DarkThemeContext';
import { Link } from 'react-router-dom';
const Footer = styled.footer`
  background-color: ${({ darkMode }) => (darkMode ? '#333' : '#f0f6ff')};
  color: ${({ darkMode }) => (darkMode ? '#fff' : '#000')};
  text-align: center;
  padding: 20px 0;
  // font-size: ${({ fontSize }) => fontSize}rem;
  font-size: 0.8rem;
`;

const FooterLink = styled.a`
  color: ${({ darkMode }) => (darkMode ? '#fff' : '#000')};
  margin: 0 1rem;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: ${({ darkMode }) => (darkMode ? '#ccc' : '#888')}; /* Example hover color */
  }
`;

const getCurrentDateTime = () => {
  const now = new Date();
  const day = now.getDate().toString().padStart(2, "0");
  const monthIndex = now.getMonth();
  const months = [
    "JAN", "FEB", "MAR", "APR", "MAY", "JUN",
    "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
  ];
  const month = months[monthIndex];
  const year = now.getFullYear();
  const time = now.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });

  return `${day}-${month}-${year} ${time}`;
};

const FooterComponent = ({ language }) => {
  const { darkMode, fontSize } = useContext(ThemeContext);

  return (
    <Footer darkMode={darkMode} fontSize={fontSize}>
      <div>
        <FooterLink as={Link} to="/i/disclaimer" rel="noopener noreferrer" darkMode={darkMode}>
          {translations[language].footerLinks.disclaimer}
        </FooterLink>
        <FooterLink as={Link} to="/i/copyrights" rel="noopener noreferrer" darkMode={darkMode}>
          {translations[language].footerLinks.privacyAndCopyright}
        </FooterLink>
        <FooterLink as={Link} to="/i/termsofuse" rel="noopener noreferrer" darkMode={darkMode}>
          {translations[language].footerLinks.termsOfUse}
        </FooterLink>
        <FooterLink as={Link} to="/i/accessibility" rel="noopener noreferrer" darkMode={darkMode}>
          {translations[language].footerLinks.accessibilityStatement}
        </FooterLink>
        <FooterLink as={Link} to="/i/grievance" rel="noopener noreferrer" darkMode={darkMode}>
          {translations[language].footerLinks.grievance}
        </FooterLink>
      </div>
      <div>{translations[language].contentOwnedBy}</div>
      <div>
        {translations[language].copyright}
        {translations[language].lastUpdatedOn} {getCurrentDateTime()}
      </div>
    </Footer>
  );
};

export default FooterComponent;
