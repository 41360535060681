import { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { useLanguage } from '../../../LanguageContext';
import { translations } from "../translations";
import CarouselComponent from '../styledcarousel';
import FooterComponent from '../footer';
import Header from '../header';
import { Apiurl } from "../../../config";
import axios from 'axios';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CryptoJS from 'crypto-js';
function encodeToBase64(plainText, secretKey) {
  const keyBytes = CryptoJS.SHA256(secretKey).toString(CryptoJS.enc.Hex).slice(0, 32);
  const key = CryptoJS.enc.Hex.parse(keyBytes);
  const iv = CryptoJS.enc.Hex.parse(keyBytes); // Use the same bytes for IV

  const encrypted = CryptoJS.AES.encrypt(plainText, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
  return encrypted.toString(); // Base64 encoded by default
}
function decodeFromBase64(encryptedText, secretKey) {

  // Create a 16-byte key
  const keyBytes = CryptoJS.SHA256(secretKey).toString(CryptoJS.enc.Hex).slice(0, 32); // Use the first 16 bytes of the hash
  const key = CryptoJS.enc.Hex.parse(keyBytes);
  const iv = CryptoJS.enc.Hex.parse(keyBytes); // Use the same bytes for IV

  const decrypted = CryptoJS.AES.decrypt(encryptedText, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
  return decrypted.toString(CryptoJS.enc.Utf8);
}
const SignInContainer = styled.div`
  position: relative;
  z-index: 1;
  overflow-x: hidden;
  max-width: 100vw;
`;
 
// Verhoeff algorithm implementation
const d = [
  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  [1, 2, 3, 4, 0, 6, 7, 8, 9, 5],
  [2, 3, 4, 0, 1, 7, 8, 9, 5, 6],
  [3, 4, 0, 1, 2, 8, 9, 5, 6, 7],
  [4, 0, 1, 2, 3, 9, 5, 6, 7, 8],
  [5, 9, 8, 7, 6, 0, 4, 3, 2, 1],
  [6, 5, 9, 8, 7, 1, 0, 4, 3, 2],
  [7, 6, 5, 9, 8, 2, 1, 0, 4, 3],
  [8, 7, 6, 5, 9, 3, 2, 1, 0, 4],
  [9, 8, 7, 6, 5, 4, 3, 2, 1, 0]
];

const p = [
  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  [1, 5, 7, 6, 2, 8, 3, 0, 9, 4],
  [5, 8, 0, 3, 7, 9, 6, 1, 4, 2],
  [8, 9, 1, 6, 0, 4, 3, 5, 2, 7],
  [9, 4, 5, 3, 1, 2, 6, 8, 7, 0],
  [4, 2, 8, 6, 5, 7, 3, 9, 0, 1],
  [2, 7, 9, 3, 8, 0, 6, 4, 1, 5],
  [7, 0, 4, 6, 9, 1, 3, 2, 5, 8]
];

const inv = [0, 4, 3, 2, 1, 5, 6, 7, 8, 9];

function validateAadhaarNumber(number) {
  let c = 0;
  const invertedArray = number.split('').map(Number).reverse();

  for (let i = 0; i < invertedArray.length; i++) {
    c = d[c][p[(i % 8)][invertedArray[i]]];
  }

  return c === 0;
}

function formatAadhaarNumber(value) {
  return value.replace(/\D/g, '').replace(/(\d{4})(?=\d)/g, '$1 ');
}
function generateRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
function generateCaptcha() {
  let num1, num2, operation;

  do {
    num1 = generateRandomNumber(0, 20);
    num2 = generateRandomNumber(0, 10);
    operation = '-';
  } while (num1 < num2);
  if (Math.random() < 0.5) {
    operation = '+';
  }
  const expression = `${num1} ${operation} ${num2}`;
  const result = operation === '+' ? num1 + num2 : num1 - num2;

  return { expression, result };
}
function Basic() {
  const navigate = useNavigate();
  const { language, toggleLanguage } = useLanguage();
  const [aadhaarNo, setAadhaarNo] = useState('');
  const [aadhaarError, setAadhaarError] = useState('');
  const [isConsentGiven, setIsConsentGiven] = useState(false);
  const [consentError, setConsentError] = useState('');
  const [captcha, setCaptcha] = useState(generateCaptcha());
  const [captchaInput, setCaptchaInput] = useState('');
  const [captchaError, setCaptchaError] = useState('');
  const [showFullText, setShowFullText] = useState(false);
  const [showFullTextTamil, setShowFullTextTamil] = useState(false);
  const [ip, setIp] = useState('');

  useEffect(() => {
    const fetchIp = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        setIp(response.data.ip);
      } catch (error) {
        console.error('Error fetching the IP address:', error);
      }
    };

    fetchIp();
  }, []);
  const toggleText = () => {
    setShowFullText(!showFullText);
  };
  const toggleTextTamil = () => {
    setShowFullTextTamil(!showFullTextTamil);
  };
  useEffect(() => {
    if (captchaInput && captchaInput !== String(captcha.result)) {
      setCaptchaError(translations[language].captchaError);
    } else {
      setCaptchaError('');
    }
  }, [captchaInput, captcha.result, language]);
  const handleOtp = async () => {
    if (!isConsentGiven) {
      setConsentError(translations[language].consentError);
    } else if (captchaInput !== String(captcha.result)) {
      setCaptchaError(translations[language].captchaError);
    } else {
      try {
        const cleanedAadhaarNo = aadhaarNo.replace(/\s/g, '');
        const currentDate = new Date().toISOString().split('T')[0].replace(/-/g, '');
        const key = currentDate + ip;
        const encryptedAadhaarNo = encodeToBase64(cleanedAadhaarNo, key);
        console.log(encryptedAadhaarNo);
        console.log(decodeFromBase64(encryptedAadhaarNo, key));
        // const response = await axios.get(Apiurl + 'sendotp/' + encryptedAadhaarNo);
        let response;
           response = await axios.post(Apiurl + "sendotp", {
            uid: encryptedAadhaarNo
          });
        const { trns_code } = response.data;
        console.log('API Response:', response.data);
        navigate('/resident/otp', { state: { aadhaarNo, trns_code } });
      } catch (error) {
        console.error('Error sending OTP:', error);
      }
    }
  };

  const regenerateCaptcha = () => {
    setCaptcha(generateCaptcha());
    setCaptchaInput('');
    setCaptchaError('');
  };

  return (
    <>
      <Header />
      <CarouselComponent />
      <SignInContainer>
        <BasicLayout >
          <Card>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center"
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                {translations[language].signInOtp}
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <MDBox component="form" role="form">
                <MDBox mb={2}>
                  <label htmlFor="aadhaarNo">
                    <b>{translations[language].aadhaarNoLabel} :</b>
                  </label>
                  <input
                    id="aadhaarNo"
                    type="text"
                    style={{
                      border: '1px solid blue',
                      padding: '8px',
                      borderRadius: '4px',
                      outline: 'none',
                      width: '100%',
                    }}
                    maxLength={19}
                    // autoComplete="off"
                    placeholder={translations[language].aadhaarNo}
                    value={aadhaarNo}
                    error={!!aadhaarError}
                    onChange={(e) => {
                      let value = e.target.value;
                      value = formatAadhaarNumber(value);
                      setAadhaarNo(value);

                      const plainValue = value.replace(/\s/g, ''); // remove spaces
                      const isAadhaar = plainValue.length === 12 && /^[2-9]{1}[0-9]{11}$/.test(plainValue);
                      const isVid = plainValue.length === 16 && /^[0-9]{16}$/.test(plainValue);

                      if ((isAadhaar && validateAadhaarNumber(plainValue)) || isVid) {
                        setAadhaarError('');
                      } else {
                        setAadhaarError(translations[language].invalidAadhaar);
                      }
                    }}
                    
                  />
                  <input id="aadhaarNo" type="hidden" value={aadhaarNo.replace(/\s/g, '')} />
                  <MDBox mb={2} style={{ height: '2rem' }}>
                    <MDTypography variant="caption" style={{ color: "red" }}>
                      {aadhaarError}
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox mb={2}>
                  <div style={{ display: 'flex' }}>
                    <input
                      type="checkbox"
                      checked={isConsentGiven}
                      style={{ width: '1.9rem', height: '1.57rem', marginRight: '10px', flexShrink: 0 }}
                      onChange={(e) => {
                        setIsConsentGiven(e.target.checked);
                        if (e.target.checked) {
                          setConsentError('');
                        }
                      }}
                    />
                    <div style={{ overflow: 'auto', height: '12.5rem' }}>
                      <div> I, the holder of the above Aadhaar Number, hereby authorize Tamil Nadu e-Governance Agency {showFullText && (<>(TNeGA) to utilize my Aadhaar Number and Biometric data for the purpose of Aadhaar eKYC / Authentication with UIDAI. I understand and consent that my biometric information will only be submitted to UIDAI's Central Identities Data Repository (CIDR) for authentication purposes, and will not be stored or shared elsewhere. Upon successful authentication, TNeGA and its Onboarded Departments/Schemes on the TN eKYC Platform will receive my Name, Date of Birth (DOB), Gender, Photograph and Address for verification purposes.</>)}
                        {!showFullText && (
                          <p onClick={toggleText} style={{ cursor: 'pointer', color: 'blue', marginLeft: '180px', marginBottom: '0px' }}>More...</p>
                        )}
                      </div>
                      <div >                       
                       மேற்கூறிய ஆதார் எண்ணை வைத்திருக்கும் நான், இதன் மூலம் எனது ஆதார் {showFullTextTamil && (<> எண் மற்றும் பயோமெட்ரிக் தரவுகளை ஆதார் eKYC/ UIDAI உடன் அங்கீகரிப்பதற்காகப் பயன்படுத்த தமிழ்நாடு மின்-ஆளுமை முகமைக்கு (TNeGA) அங்கீகாரம் அளிக்கிறேன். எனது பயோமெட்ரிக் தகவல் அங்கீகார நோக்கங்களுக்காக UIDAI இன் மத்திய அடையாள தரவுக் களஞ்சியத்தில் (CIDR) மட்டுமே சமர்ப்பிக்கப்படும் என்பதையும், வேறு எங்கும் சேமித்து வைக்கப்படாது அல்லது பகிரப்படாது என்பதையும் புரிந்துகொண்டு சம்மதிக்கிறேன். வெற்றிகரமான அங்கீகரிப்புக்குப் பிறகு, TNeGA மற்றும் TN eKYC பிளாட்ஃபார்மில் உள்ள அதன் ஆன்போர்டு துறைகள்/திட்டங்கள் சரிபார்ப்பு நோக்கங்களுக்காக எனது பெயர், பிறந்த தேதி (DOB), பாலினம், புகைப்படம் மற்றும் முகவரியைப் பெறும்.</>)}</div>
                      {!showFullTextTamil && (
                        <p onClick={toggleTextTamil} style={{ cursor: 'pointer', color: 'blue', marginLeft: '180px' }}>More...</p>
                      )}
                    </div>
                  </div>
                  <MDBox mb={2} style={{ height: '2rem' }}>
                    <MDTypography variant="caption" style={{ color: "red" }}>
                      {consentError}
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox mb={2}>

                  <div htmlFor="captchaInput" style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <b>{translations[language].captchaLabel} :</b>
                    <MDTypography variant="body1" color="text">
                      {captcha.expression} =
                    </MDTypography>
                    <input
                      id="captchaInput"
                      type="text"
                      placeholder={translations[language].captchaLabel}
                      maxLength={2}
                      style={{
                        border: '1px solid blue',
                        padding: '8px',
                        borderRadius: '4px',
                        outline: 'none',
                        width: '80px',
                      }}
                      value={captchaInput}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d*$/.test(value)) { // Only allow numeric input
                          setCaptchaInput(value);
                        }
                      }}
                      error={!!captchaError}
                    />
                    <p onClick={regenerateCaptcha} style={{ cursor: 'pointer', color: 'blue' }}><AutorenewIcon /></p>
                  </div>
                  <MDBox mb={2} style={{ height: '2rem' }}>
                    <MDTypography variant="caption" style={{ color: "red" }}>
                      {captchaError}
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox mt={4} mb={1}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    onClick={handleOtp}
                    disabled={!aadhaarNo || !!aadhaarError} // Disable the button if Aadhaar number is empty or if there's an error
                  >
                    {translations[language].signInButton}
                  </MDButton>
                </MDBox>
              </MDBox>
            </MDBox>
          </Card>

        </BasicLayout>
      </SignInContainer>
      <FooterComponent language={language} />
    </>
  );
}

export default Basic;