

export default {
  sales: {
    // labels: ["Iris", "Fp", "Otp", "Face", "Aug", "Sep", "Oct", "Nov", "Dec"],

    labels: ["Iris", "Fingerprint", "OTP", "Face"],
    datasets: { label: "Mobile apps", data: [50, 40, 300, 320, 500] },
  },
  tasks: {
    labels: ["Rev", "e-sev", "web", "mob", "mbc"],
    datasets: { label: "Desktop apps", data: [50, 40, 300, 220, 500] },
  },
};
