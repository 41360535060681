import React, { useState, useEffect } from "react";
import { useLocation, useNavigate  } from "react-router-dom";
import { Box, Typography, Button, Tabs, Tab, Card, CardContent, Modal, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "templates/LayoutContainers/DashboardLayout";
import Face from "../../assets/images/facecapture.jpeg";
import OTP from "../../assets/images/digitalotp.jpeg";
import Iris from "../../assets/images/eyeiris.jpeg";
import Fingerimage from "../../assets/images/fp.png";
import DashboardNavbar from "templates/Navbars/DashboardNavbar";
import { useModal } from "../../ModalContext";
import CloseIcon from '@mui/icons-material/Close';
import axios from "axios";
import { Apiurl } from "../../config";
import { useLanguage } from '../../LanguageContext';
import { translations } from "../authentication/translations";
import MDTypography from "components/MDTypography";
import { CheckBox as CheckBoxIcon, Sync as SyncIcon } from '@mui/icons-material';
import OTPModal from "./otpmodel";
import CryptoJS from 'crypto-js';
function Dashboard() {
  const [tabValue, setTabValue] = useState(0);
  const { isModalOpen, closeModal } = useModal();
  const [successModalOpen, setSuccessModalOpen] = useState(false); // State for success modal
  const [showAllBoxes, setShowAllBoxes] = useState(false); // State to manage showing all boxes
  const [open, setOpen] = useState(false);
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [schemes, setSchemes] = useState([]);
  const [ekycData, setEkycData] = useState([]);
  const [isConsentGiven, setIsConsentGiven] = useState(false);
  const { language, toggleLanguage } = useLanguage();
  const [consentError, setConsentError] = useState('');
  const [isFingerprintChecked, setIsFingerprintChecked] = useState(false); // Added state for Fingerprint
  const [isIrisChecked, setIsIrisChecked] = useState(false);
  const [isFaceChecked, setIsFaceChecked] = useState(false);
  const [isOTPChecked, setIsOTPChecked] = useState(false);
  const [currentXML, setCurrentXML] = useState('');
  const [currentURL, setCurrentURL] = useState('');
  const [currentDevice, setCurrentDevice] = useState('');
  const [trnsCode, setTrnsCode] = useState(null);
  const [responses, setResponses] = useState({});
  const [modalities, setModalities] = useState('');
  const [ip, setIp] = useState('');
  const navigate = useNavigate();  
  useEffect(() => {
    const token = localStorage.getItem("Authorization");

    if (!token) {
      navigate("/home");
    }
  }, [navigate]);
  function encodeToBase64(plainText, secretKey) {
    const keyBytes = CryptoJS.SHA256(secretKey).toString(CryptoJS.enc.Hex).slice(0, 32);
    const key = CryptoJS.enc.Hex.parse(keyBytes);
    const iv = CryptoJS.enc.Hex.parse(keyBytes); // Use the same bytes for IV

    const encrypted = CryptoJS.AES.encrypt(plainText, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    return encrypted.toString(); // Base64 encoded by default
  }
  function decodeFromBase64(encryptedText, secretKey) {

    // Create a 16-byte key
    const keyBytes = CryptoJS.SHA256(secretKey).toString(CryptoJS.enc.Hex).slice(0, 32); // Use the first 16 bytes of the hash
    const key = CryptoJS.enc.Hex.parse(keyBytes);
    const iv = CryptoJS.enc.Hex.parse(keyBytes); // Use the same bytes for IV
  
    const decrypted = CryptoJS.AES.decrypt(encryptedText, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
  useEffect(() => {
    const fetchIp = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        setIp(response.data.ip);
      } catch (error) {
        console.error('Error fetching the IP address:', error);
      }
    };

    fetchIp();
  }, []);
  const handleCloseVerify = () => {
    setSuccessModalOpen(false);
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const { state } = useLocation();
  const { aadhaarNo, maskedAadhaar, username, dobyear, gender, photo } = state || {};
  const token = localStorage.getItem("Authorization");
  const headers = {
    Authorization: `Bearer ${token}`
  };
  useEffect(() => {
    const fetchSchemes = async () => {
      try {
        let response;
        if (window.location.href.includes("localhost")) {
          response = await axios.get(Apiurl + "get/schemes");
        } else {
          response = await axios.get(Apiurl + "get/schemes", { headers });
        }
        const schemesData = Object.entries(response.data.data);
        setSchemes(schemesData);
      } catch (error) {
        console.error("Error fetching schemes data:", error);
      }
    };

    const fetchModalities = async () => {
      try {
        let response;
        if (window.location.href.includes("localhost")) {
          response = await axios.get(Apiurl + "get/modalities");
        } else {
          response = await axios.get(Apiurl + "get/modalities", { headers });
        }
        const ModalitiesData = Object.values(response.data.data);
        setModalities(ModalitiesData); // Assuming you want to set the state with this data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchekycStatus = async () => {
      try {
        let response;
        if (window.location.href.includes("localhost")) {
          response = await axios.get(Apiurl + "get/ekycstatus");
        } else {
          response = await axios.get(Apiurl + "get/ekycstatus", { headers });
        }
        const responseData = response.data.data;
        setEkycData(Object.entries(responseData));
      } catch (error) {
        console.error("Error fetching ekyc status data:", error);
      }
    };

    fetchSchemes();
    fetchekycStatus();
    fetchModalities();
  }, []);
  const handleFingerprintClick = async () => {
    setIsFingerprintChecked(true);
    setIsOTPChecked(false);
    setIsIrisChecked(false);
    const XML = '<?xml version="1.0"?> <PidOptions ver="1.0"> <Opts fCount="1" fType="2" iCount="0" pCount="0" format="0" pidVer="2.0" timeout="10000" wadh="RZ+k4w9ySTzOibQdDHPzCFqrKScZ74b3EibKYy1WyGw=" posh="UNKNOWN" env="P" /> <CustOpts><Param name="mantrakey" value="undefined" /></CustOpts> </PidOptions>';
    setCurrentXML(XML);
    const urls = ["http://127.0.0.1:11100/", "http://127.0.0.1:11101/"];

    const checkURL = async (url, method = 'RDSERVICE') => {
      try {
        const response = await fetch(url, {
          method: method,
        });
        if (response.ok) {
          return url;
        }
      } catch (error) {
        console.error(`Error checking URL ${url}:`, error);
      }
      return null;
    };

    const setAvailableURL = async () => {
      for (const url of urls) {
        const result = await checkURL(url);
        if (result) {
          return result;
        }
      }
      return null;
    };

    const availableURL = await setAvailableURL();
    if (availableURL) {
      setCurrentURL(availableURL);
    } else {
      console.error("No available URL found for the device.");
    }

    setCurrentDevice("ekyc/fp");
  };

  const handleIrisClick = async () => {
    setIsIrisChecked(true);
    setIsOTPChecked(false);
    setIsFingerprintChecked(false);
    const XML = '<?xml version="1.0"?> <PidOptions ver="1.0"> <Opts fCount="0" fType="0" iCount="1" iType="1" pCount="0" format="0"   pidVer="2.0" timeout="10000" wadh="T9nIfCslZnsX4pR6o1CzBDHp7MUiYne6QySOZvmB3Rk=" posh="UNKNOWN" env="P" /> <CustOpts><Param name="mantrakey" value="undefined" /></CustOpts> </PidOptions>';
    setCurrentXML(XML);
    const urls = ["http://127.0.0.1:11100/", "http://127.0.0.1:11101/"];

    const checkURL = async (url, method = 'RDSERVICE') => {
      try {
        const response = await fetch(url, {
          method: method,
        });
        if (response.ok) {
          return url;
        }
      } catch (error) {
        console.error(`Error checking URL ${url}:`, error);
      }
      return null;
    };

    const setAvailableURL = async () => {
      for (const url of urls) {
        const result = await checkURL(url);
        if (result) {
          return result;
        }
      }
      return null;
    };

    const availableURL = await setAvailableURL();
    if (availableURL) {
      setCurrentURL(availableURL);
    } else {
      console.error("No available URL found for the device.");
    }
    setCurrentDevice("ekyc/iris");
  };

  const handleOtpClick = () => {
    setIsOTPChecked(true);
    setIsIrisChecked(false);
    setIsFingerprintChecked(false);
  };
  const handleCloseOTPModal = () => {
    setOtpModalOpen(false);
  };
  const handleYes = async () => {
    const cleanedAadhaarNo = aadhaarNo.replace(/\s/g, '');
    const currentDate = new Date().toISOString().split('T')[0].replace(/-/g, '');
    const key = currentDate + ip;
    const encryptedAadhaarNo = encodeToBase64(cleanedAadhaarNo, key);
    if (isOTPChecked) {
      let response;
      if (window.location.href.includes("localhost")) {
        response = axios.post(Apiurl + 'sendotp/')
          .then(response => {
            const { trns_code } = response.data;
            setTrnsCode(trns_code);
            console.log('API Response:', response.data);
          })
          .catch(error => {
            console.error('Error sending OTP:', error);
          });
      } else {
        response = axios.post(Apiurl + 'sendotp/', {
          uid: encryptedAadhaarNo
        })
          .then(response => {
            const { trns_code } = response.data;
            setTrnsCode(trns_code);
            console.log('API Response:', response.data);
          })
          .catch(error => {
            console.error('Error sending OTP:', error);
          });
      }
      closeModal();
      setOtpModalOpen(true);
    } else {
      const MethodCapture = 'rd/capture';

      try {
        const response = await axios({
          method: 'CAPTURE',
          url: currentURL + MethodCapture,
          data: currentXML,
          headers: {
            'Content-Type': 'text/xml; charset=utf-8',
          },
        });
        const data = response.data;
        // Check if the response contains an error
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(data, "text/xml");
        const respNode = xmlDoc.getElementsByTagName("Resp")[0];

        if (respNode) {
          const errCode = respNode.getAttribute("errCode");
          const errInfo = respNode.getAttribute("errInfo");

          if (errCode !== "0") {
            alert("BioMetric Device Not Connected");
            console.log(`Error Code: ${errCode}, Error Info: ${errInfo}`);
            return;
          }
        }
        const deviceInfoNode = xmlDoc.getElementsByTagName("DeviceInfo")[0];
        const additionalInfoNode = deviceInfoNode.getElementsByTagName("additional_info")[0];
        const params = additionalInfoNode.getElementsByTagName("Param");
        let srno = null;

        for (let i = 0; i < params.length; i++) {
          if (params[i].getAttribute("name") === "srno") {
            srno = params[i].getAttribute("value");
            break;
          }
        }

        if (!srno) {
          throw new Error("srno not found in the response.");
        }
        const dataupdate = btoa(data); 
        await axios.post(Apiurl + currentDevice + "/" + encryptedAadhaarNo + '/0/' + srno, dataupdate)
          .then(response => {
            console.log('Response:', response);
            if (response.status === 200) {
              // If the API call is successful, navigate to the dashboard
              console.log(' ekyc API Response:', response.data);
              if (response.data.errdesc) {
                alert(response.data.errdesc);
                closeModal();
              } else {
                closeModal();
                setSuccessModalOpen(true);
                setTimeout(() => {
                  setSuccessModalOpen(false);
                }, 50000);
              }
            }
          })
          .catch(error => {
            console.error('Error:', error);
          });


      } catch (error) {
        console.error("Error capturing fingerprint:", error);
      }
    }
  };


  const handleMoreClick = () => {
    setShowAllBoxes(!showAllBoxes);
  };


  const handleAddScheme = () => {
    // Logic for adding a scheme goes here
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSync = async (schemeId) => {
    try {
      const cleanedAadhaarNo = aadhaarNo.replace(/\s/g, '');
      const currentDate = new Date().toISOString().split('T')[0].replace(/-/g, '');
      const key = currentDate + ip;
      const encryptedAadhaarNo = encodeToBase64(cleanedAadhaarNo, key);
      let response;
          response = await axios.post(Apiurl + "get/sync", {
            uid: encryptedAadhaarNo,
            schemeId: schemeId
        });
      setResponses((prevResponses) => ({
        ...prevResponses,
        [schemeId]: response.data.data, // Extracting data object from response
      }));
    } catch (error) {
      console.error('Error syncing scheme:', error);
      // Handle error scenario
    }
  };

  let arrayBuffer;
  if (photo && photo.length) {
    arrayBuffer = new Uint8Array(photo.length);
    for (let i = 0; i < photo.length; i++) {
      arrayBuffer[i] = photo.charCodeAt(i);
    }
  }
  const blob = new Blob([arrayBuffer], { type: 'image/jpeg' });
  const imageUrl = URL.createObjectURL(blob);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} sx={{ borderRadius: 2, textAlign: 'center', padding: '20px' }}>
        <Card sx={{ maxWidth: 800, margin: 'auto' }}>
          <CardContent>
            <Typography variant="h4">Aadhaar eKYC - {maskedAadhaar}</Typography>
            <img src={imageUrl} alt="Profile Picture" style={{ width: '150px', height: '150px', borderRadius: '50%', objectFit: 'fill' }} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h5">{username}</Typography>
              <Typography variant="body1">{gender}{" "}{dobyear} years</Typography>
            </Box>
          </CardContent>
        </Card>

        <div className="container mt-4">
          <Tabs
            value={tabValue}
            onChange={handleChange}
            variant="fullWidth"
            aria-label="eKYC tabs"
            className="mb-3"
          >
            <Tab label="My eKYC Status" />
            <Tab label="My Schemes" />
          </Tabs>

          {tabValue === 0 && (
            <div className="table-responsive">
              <table className="table table-bordered table-hover">
                <thead className="table-light">
                  <tr>
                    <th scope="col">Status</th>
                    <th scope="col">Details</th>
                    <th scope="col">Date</th>
                  </tr>
                </thead>
                <tbody>
                  {ekycData.map(([modality, details], index) => (
                    <tr key={index}>
                      <td className="text-center align-middle">
                        <CheckBoxIcon style={{ color: 'green', marginRight: '5px' }} />
                        Success
                      </td>
                      <td className="text-center align-middle">
                        Successful eKYC ({modality}) <br />
                        by {details.Mode}
                      </td>
                      <td className="text-center align-middle">
                        Completed on {details.Date}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {tabValue === 1 && (
            <div className="table-responsive">
              <table className="table table-bordered table-hover">
                <thead className="table-light">
                  <tr>
                    <th scope="col">Scheme Name</th>
                    <th scope="col">eKYC details</th>
                    <th scope="col">eKYC Date</th>
                    <th scope="col">Sync</th>
                  </tr>
                </thead>
                <tbody>
                  {schemes.map(([schemeId, schemeName], index) => (
                    <tr key={index}>
                      <td className="text-center align-middle">{schemeName}</td>
                      <td className="text-center align-middle">
                        {responses[schemeId] ? (
                          <>
                            Successful eKYC ({responses[schemeId].Modality}) <br />
                            by {responses[schemeId].Mode}
                          </>
                        ) : (
                          <>Pending</>
                        )}
                      </td>
                      <td className="text-center align-middle">
                        {responses[schemeId] ? (
                          <>Completed on {responses[schemeId].TS} <br />{responses[schemeId].Status}</>
                        ) : (
                          <>Not available</>
                        )}
                      </td>
                      <td className="text-center align-middle">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleSync(schemeId)}
                          style={{ color: 'white' }}
                        >
                          <SyncIcon />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddScheme}
                className="mt-3"
                style={{ color: 'white' }}
              >
                Add Scheme / Services
              </Button>
            </div>
          )}
        </div>



        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>No Schemes / Services Available</DialogTitle>
          <DialogContent>
            <Typography>There are currently no schemes / services available for you. Please check back later.</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </MDBox>
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="ekyc-modal-title"
        aria-describedby="ekyc-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            height: '90%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            overflowY: 'scroll'
          }}
        >
          <IconButton onClick={closeModal} sx={{ position: 'absolute', top: 0, right: 0 }}>
            <CloseIcon />
          </IconButton>
          <Typography id="ekyc-modal-title" variant="h2" component="h2" sx={{ mt: 2, textAlign: 'center' }}>
            Verify Details
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2 }}>
            <Typography id="ekyc-modal-description">
              <p><b>Name : </b>{username}</p>
              <p><b>Aadhaar No : </b>{maskedAadhaar}</p>
              <p><b>Age : </b>{dobyear}</p>
              <p><b>Gender : </b>{gender}</p>
            </Typography>
            <img src={imageUrl} alt="Profile Picture" style={{ width: '150px', height: '150px', borderRadius: '50%', objectFit: 'fill' }} />
          </Box>
          <MDBox mb={2}>
            <label>
              <input
                type="checkbox"
                checked={isConsentGiven}
                style={{ width: '25px', height: '20px' }}
                onChange={(e) => {
                  setIsConsentGiven(e.target.checked);
                  if (e.target.checked) {
                    setConsentError('');
                  }
                }}
              />{translations[language].consentText}
            </label>
            {consentError && (
              <MDTypography variant="caption" color="error">
                {consentError}
              </MDTypography>
            )}
          </MDBox>
          <MDBox mb={2} display="flex" justifyContent="space-around">
            <label style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="radio"
                name="verificationMethod"
                checked={isFingerprintChecked}
                onChange={handleFingerprintClick}
              />
              <img src={Fingerimage} alt="FingerPrint Picture" style={{ width: '100px', height: '100px', marginLeft: "0.5rem", marginRight: "0.5rem", borderRadius: '50%', objectFit: 'cover' }} />
              FINGERPRINT
            </label>
            <label style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="radio"
                name="verificationMethod"
                checked={isIrisChecked}
                onChange={handleIrisClick}
              />
              <img src={Iris} alt="Iris Picture" style={{ width: '100px', height: '100px', marginLeft: "0.5rem", marginRight: "0.5rem", borderRadius: '50%', objectFit: 'cover' }} />
              IRIS
            </label>
            {/* <label style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="radio"
                name="verificationMethod"
                checked={isFaceChecked}
                onChange={() => setIsFaceChecked(true)}
              />
              <img src={Face} alt="Face Picture" style={{ width: '100px', height: '100px', marginLeft: "0.5rem", marginRight: "0.5rem", borderRadius: '50%', objectFit: 'cover' }} />
              Face
            </label> */}
            <label style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="radio"
                name="verificationMethod"
                checked={isOTPChecked}
                onChange={handleOtpClick}
              />
              <img src={OTP} alt="OTP Picture" style={{ width: '100px', height: '100px', marginLeft: "0.5rem", marginRight: "0.5rem", borderRadius: '50%', objectFit: 'cover' }} />
              Send OTP
            </label>
          </MDBox>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button variant="contained" color="primary" style={{ color: 'white' }} onClick={handleYes} disabled={!isConsentGiven}>
              Continue
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={successModalOpen}
        onClose={handleCloseVerify}
        aria-labelledby="success-modal-title"
        aria-describedby="success-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 1,
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
            }}
            onClick={handleCloseVerify}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="success-modal-title" variant="h6" component="h2">
            Your details have been successfully verified.
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2 }}>
            <Box>
              <p><b>Name: </b>{username}</p>
              <p><b>Aadhaar No: </b>{maskedAadhaar}</p>
              <p><b>Age: </b>{dobyear}</p>
              <p><b>Gender: </b>{gender}</p>
            </Box>
            <img src={imageUrl} alt="Profile Picture" style={{ width: '150px', height: '150px', borderRadius: '50%', objectFit: 'fill' }} />
          </Box>
        </Box>
      </Modal>
      <OTPModal
        open={otpModalOpen}
        handleClose={handleCloseOTPModal}
        aadhaarNo={aadhaarNo}
        trnsCode={trnsCode}
      />
    </DashboardLayout>
  );
}

export default Dashboard;
