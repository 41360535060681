import PropTypes from "prop-types";

//  React components
import MDBox from "components/MDBox";

function Footer() {
  return (
    <MDBox width="100%" />
  );
}

// Setting default values for the props of Footer
Footer.defaultProps = {
  company: { href: "", name: "" },
  links: [],
};

// Typechecking props for the Footer
Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;