class MessageParser {
  constructor(actionProvider) {
    this.actionProvider = actionProvider;
  }

  parse(message) {
    if (message.toLowerCase().includes('grievance')) {
      this.actionProvider.handleOptions();
    }
  }
}

export default MessageParser;