import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { useLanguage } from '../LanguageContext';
import { translations } from "../layouts/authentication/translations";
import { Container } from "./styledComponents";
import Header from '../layouts/authentication/header';
import FooterComponent from '../layouts/authentication/footer';

const MainContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  padding: 1rem;
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.5rem;
`;

const Column = styled.div`
  flex: 1;
  padding: 0 0.5rem;
  max-width: 50%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const Input = styled.input`
  margin: 0.5rem 0;
  padding: 0.5rem;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
`;

const Select = styled.select`
  margin: 0.5rem 0;
  padding: 0.5rem;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
`;

const Textarea = styled.textarea`
  margin: 0.5rem 0;
  padding: 0.5rem;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
  height: 100px;
`;

const Button = styled.button`
  margin-top: 1rem;
  padding: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 0.875rem;
  margin: 0.5rem 0;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Popup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem;
  background: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  animation: ${fadeIn} 0.5s ease-in-out;
  text-align: center;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const Grievance = () => {
  const { language } = useLanguage();
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [grievanceType, setGrievanceType] = useState("");
  const [content, setContent] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState({});

  const validateField = (fieldName, value) => {
    const newErrors = { ...errors };

    switch (fieldName) {
      case 'name':
        newErrors.name = value ? "" : translations[language].nameRequired || "Name is required";
        break;
      case 'mobile':
        newErrors.mobile = value ? "" : translations[language].mobileRequired || "Mobile number is required";
        break;
      case 'grievanceType':
        newErrors.grievanceType = value ? "" : translations[language].grievanceTypeRequired || "Grievance type is required";
        break;
      case 'content':
        newErrors.content = value ? "" : translations[language].contentRequired || "Content is required";
        break;
      default:
        break;
    }

    setErrors(newErrors);
  };

  const handleChange = (fieldName, value) => {
    switch (fieldName) {
      case 'name':
        setName(value);
        break;
      case 'mobile':
        setMobile(value);
        break;
      case 'grievanceType':
        setGrievanceType(value);
        break;
      case 'content':
        setContent(value);
        break;
      default:
        break;
    }
    validateField(fieldName, value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!name) newErrors.name = translations[language].nameRequired;
    if (!mobile) newErrors.mobile = translations[language].mobileRequired;
    if (!grievanceType) newErrors.grievanceType = translations[language].grievanceTypeRequired;
    if (!content) newErrors.content = translations[language].contentRequired;

    if (Object.keys(newErrors).length === 0) {
      setIsSubmitted(true);
      setName("");
      setMobile("");
      setGrievanceType("");
      setContent("");
      setErrors({});
    } else {
      setErrors(newErrors);
    }
  };

  const closePopup = () => {
    setIsSubmitted(false);
  };

  return (
    <>
      <Container>
        <Header />
        <MainContent>
          <h2>{translations[language].footerLinks.grievance}</h2>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Column>
                <Input
                  type="text"
                  placeholder={translations[language].name}
                  value={name}
                  onChange={(e) => handleChange('name', e.target.value)}
                />
                {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
              </Column>
              <Column>
                <Input
                  type="tel"
                  placeholder={translations[language].mobileno}
                  value={mobile}
                  onChange={(e) => handleChange('mobile', e.target.value)}
                  maxLength={10}
                />
                {errors.mobile && <ErrorMessage>{errors.mobile}</ErrorMessage>}
              </Column>
            </Row>
            <Select
              value={grievanceType}
              onChange={(e) => handleChange('grievanceType', e.target.value)}
            >
              <option value="" disabled>{translations[language].selectgrievance}</option>
              <option value="type1">{translations[language].ekycrelated}</option>
              <option value="type2">{translations[language].schemesrelated}</option>
              <option value="type3">{translations[language].portalrelated}</option>
              <option value="type4">{translations[language].mobileapprelated}</option>
              <option value="type5">{translations[language].others}</option>
            </Select>
            {errors.grievanceType && <ErrorMessage>{errors.grievanceType}</ErrorMessage>}
            <Textarea
              placeholder={translations[language].content}
              value={content}
              onChange={(e) => handleChange('content', e.target.value)}
            />
            {errors.content && <ErrorMessage>{errors.content}</ErrorMessage>}
            <Button type="submit">{translations[language].submit}</Button>
          </Form>
        </MainContent>
        <FooterComponent language={language} />
      </Container>
      {isSubmitted && (
        <>
          <Overlay onClick={closePopup} />
          <Popup>
            <p>{translations[language].formSubmitted}</p>
            <Button onClick={closePopup}>{translations[language].close}</Button>
          </Popup>
        </>
      )}
    </>
  );
};

export default Grievance;
