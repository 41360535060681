// src/contexts/ThemeContext.js
import React, { createContext, useState, useEffect } from 'react';

const ThemeContext = createContext();

const ToggleTheme = ({ children }) => {
  const [darkMode, setDarkMode] = useState(false);
  const [fontSize, setFontSize] = useState(1);

  useEffect(() => {
    if (darkMode) {
      document.body.style.backgroundColor = '#333';
      document.body.style.color = '#fff';
    } else {
      document.body.style.backgroundColor = '#fff';
      document.body.style.color = '#000';
    }
    document.body.style.fontSize = `${fontSize}rem`;
  }, [darkMode, fontSize]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

 const increaseFontSize = () => {
    setFontSize(prevSize => prevSize + 0.1); // Increase font size by 0.1rem
  };

  const decreaseFontSize = () => {
    setFontSize(prevSize => Math.max(0.75, prevSize - 0.1)); // Minimum font size is 0.75rem (12px)
  };

  return (
    <ThemeContext.Provider value={{ darkMode, toggleDarkMode, fontSize, increaseFontSize, decreaseFontSize }}>
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ToggleTheme };
