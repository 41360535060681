import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { useLanguage } from '../../../LanguageContext';
import { translations } from "../translations";
import CarouselComponent from '../styledcarousel';
import FooterComponent from '../footer';
import Header from '../header';
import axios from 'axios';
import { Apiurl } from "../../../config";
import CryptoJS from 'crypto-js';

const SignInContainer = styled.div`
  position: relative;
  z-index: 1;
  overflow-x: hidden;
  max-width: 100vw;
`;

const OtpContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid blue;
  padding: 8px;
  border-radius: 4px;
  width: 100%;
`;

const OtpInput = styled.input`
  border: none;
  outline: none;
  flex: 1;
`;

const TimerResendLink = styled.span.attrs(props => ({
    style: {
      color: props.isTimerRunning ? 'red' : 'blue',
    }
  }))`
    cursor: pointer;
  `;

function Basic() {
    const navigate = useNavigate();
    const [showMenu, setShowMenu] = useState(false);
    const { language, toggleLanguage } = useLanguage();
    const [otp, setOtp] = useState('');
    const [otpError, setOtpError] = useState('');
    const [timer, setTimer] = useState(60); // Initial timer value of 60 seconds
    const [resendClickCount, setResendClickCount] = useState(0);
    const [isTimerRunning, setIsTimerRunning] = useState(true);
    const { state } = useLocation();
    const { aadhaarNo, trns_code } = state || {}; // Access Aadhaar number from location state
    const maskedAadhaar = aadhaarNo ? `XXXX XXXX ${aadhaarNo.slice(-4)}` : '';
    const [ip, setIp] = useState('');
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    function encodeToBase64(plainText, secretKey) {
        const keyBytes = CryptoJS.SHA256(secretKey).toString(CryptoJS.enc.Hex).slice(0, 32);
        const key = CryptoJS.enc.Hex.parse(keyBytes);
        const iv = CryptoJS.enc.Hex.parse(keyBytes); // Use the same bytes for IV
      
        const encrypted = CryptoJS.AES.encrypt(plainText, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
        return encrypted.toString(); // Base64 encoded by default
      }
      function decodeFromBase64(encryptedText, secretKey) {

        // Create a 16-byte key
        const keyBytes = CryptoJS.SHA256(secretKey).toString(CryptoJS.enc.Hex).slice(0, 32); // Use the first 16 bytes of the hash
        const key = CryptoJS.enc.Hex.parse(keyBytes);
        const iv = CryptoJS.enc.Hex.parse(keyBytes); // Use the same bytes for IV
      
        const decrypted = CryptoJS.AES.decrypt(encryptedText, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
        return decrypted.toString(CryptoJS.enc.Utf8);
      }
    useEffect(() => {
      const fetchIp = async () => {
        try {
          const response = await axios.get('https://api.ipify.org?format=json');
          setIp(response.data.ip);
        } catch (error) {
          console.error('Error fetching the IP address:', error);
        }
      };
  
      fetchIp();
    }, []);
    useEffect(() => {
        let intervalId;
        if (isTimerRunning) {
            intervalId = setInterval(() => {
                setTimer(prevTimer => {
                    if (prevTimer === 1) {
                        clearInterval(intervalId);
                        setIsTimerRunning(false);
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }
        return () => clearInterval(intervalId);
    }, [isTimerRunning]);
    
    useEffect(() => {
        if (isInitialLoad) {
          const navigationEntries = performance.getEntriesByType('navigation');
          if (navigationEntries.length > 0 && navigationEntries[0].type === 'reload') {
            navigate('/resident');
          }
          setIsInitialLoad(false); // Ensure this only runs on initial load
        }
      }, [isInitialLoad, navigate]);

    const handleResendOTP = async () => {
        setTimer(60); // Reset timer to 60 seconds
        setIsTimerRunning(true); // Start the timer
        setResendClickCount(prevCount => prevCount + 1); // Increment the resend click count
        if (resendClickCount + 1 >= 3) {
            navigate('/'); // Redirect to home page if clicked thrice
        } else {
            try {
                const cleanedAadhaarNo = aadhaarNo.replace(/\s/g, '');
                const currentDate = new Date().toISOString().split('T')[0].replace(/-/g, '');
                const key = currentDate + ip;
                const encryptedAadhaarNo = encodeToBase64(cleanedAadhaarNo, key);
                // const response = await axios.get(Apiurl + 'sendotp/' + aadhaarNo.replace(/\s/g, ''));
                let response;
                   response = await axios.post(Apiurl + "sendotp", {
                    uid: encryptedAadhaarNo
                  });
                const { trns_code } = response.data;
                console.log('API Response:', response.data);
                navigate('/resident/otp', { state: { aadhaarNo, trns_code } });
            } catch (error) {
                console.error('Error sending OTP:', error);
            }
        }
    };

    const handleSignIn = async (event) => {
        event.preventDefault();
        try {
            const cleanedAadhaarNo = aadhaarNo.replace(/\s/g, '');
            const currentDate = new Date().toISOString().split('T')[0].replace(/-/g, '');
            const key = currentDate + ip;
            const encryptedAadhaarNo = encodeToBase64(cleanedAadhaarNo, key);
                // const response = await axios.get(Apiurl + 'sendotp/' + aadhaarNo.replace(/\s/g, ''));
                let response;
                if (window.location.href.includes("localhost")) {
                   response = await axios.post(Apiurl + "ekyc/otp/");
                } else {
                   response = await axios.post(Apiurl + "ekyc/otp/", {
                    uid: encryptedAadhaarNo,
                    pid:otp,
                    txn:trns_code
                  });
                }
            // const response = await axios.post(Apiurl + 'ekyc/otp/' + aadhaarNo.replace(/\s/g, '') + '/' + otp + '/' + trns_code);
            if (response.status === 200) {
                // If the API call is successful, navigate to the dashboard
                console.log('ekyc API Response:', response.data);
                localStorage.setItem("Authorization", response.data.Authorization);
                const decodedXML = atob(response.data.Pht);
                const currentYear = new Date().getFullYear();
                const username = response.data.Poi.name;
                let dob = response.data.Poi.dob;
                if (dob.includes('-')) {
                    dob = dob.split('-')[2];
                }
                dob = parseInt(dob, 10);
                const dobyear = (currentYear - dob);
                const gender = response.data.Poi.gender === 'M' ? 'Male' : response.data.Poi.gender === 'F' ? 'Female' : 'Transgender';
                const photo = decodedXML;
                navigate('/resident/dashboard', { state: { aadhaarNo, maskedAadhaar, username, dobyear, gender, photo } });
            } else {
                setOtpError(translations[language].invalidOtp);
                navigate('/resident/dashboard', { state: { aadhaarNo, maskedAadhaar } });
            }
        } catch (error) {
            console.error("Failed to verify OTP:", error);
            setOtpError(translations[language].invalidOtp);
        }
    };

    return (
        <>
            <Header />
            <CarouselComponent />
            <SignInContainer>
                <BasicLayout>
                    <Card>
                        <MDBox
                            variant="gradient"
                            bgColor="info"
                            borderRadius="lg"
                            coloredShadow="info"
                            mx={2}
                            mt={-3}
                            p={2}
                            mb={1}
                            textAlign="center"
                        >
                            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                                {translations[language].enterOtp}
                            </MDTypography>
                        </MDBox>
                        <MDBox pt={4} pb={3} px={3}>
                            <MDBox component="form" role="form" onSubmit={handleSignIn}>
                                <MDBox mb={2}>
                                    <MDTypography>
                                        <label htmlFor="aadhaarNo">
                                            <b>{translations[language].aadhaarNoLabel} :</b>
                                        </label>{" " + maskedAadhaar}
                                    </MDTypography>
                                </MDBox>
                                <MDBox mb={2}>
                                    <label htmlFor="otp">
                                        <b>{translations[language].enterOtp} :</b>
                                    </label>
                                    <OtpContainer>
                                        <OtpInput
                                            type="text"
                                            placeholder={translations[language].enterOtp}
                                            value={otp}
                                            maxLength={6}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                setOtp(value);
                                                const otpPattern = /^\d{6}$/;
                                                if (otpPattern.test(value)) {
                                                    setOtpError('');
                                                } else {
                                                    setOtpError(translations[language].invalidOtp);
                                                }
                                            }}
                                        />
                                        <TimerResendLink
                                            isTimerRunning={isTimerRunning}
                                            onClick={!isTimerRunning ? handleResendOTP : undefined}
                                        >
                                            {isTimerRunning ? `${timer}s` : translations[language].resendOTP}
                                        </TimerResendLink>
                                    </OtpContainer>
                                    {otpError && <span style={{ color: 'red' }}>{otpError}</span>}
                                </MDBox>
                                <MDBox mt={4} mb={1}>
                                    <MDButton
                                        variant="gradient"
                                        color="info"
                                        fullWidth
                                        type="submit"
                                    >
                                        {translations[language].signInButton}
                                    </MDButton>
                                </MDBox>
                            </MDBox>
                        </MDBox>
                    </Card>
                </BasicLayout>
            </SignInContainer>
            <FooterComponent language={language} />
        </>
    );
}

export default Basic;