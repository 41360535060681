import { useParams } from 'react-router-dom';
import { useState, useEffect } from "react";
import DOMPurify from 'dompurify';
import Header from './layouts/authentication/header';
import FooterComponent from './layouts/authentication/footer';
import { useLanguage } from './LanguageContext';

function InfoPage() {
  const { id } = useParams();
  const [textContent, setTextContent] = useState('');
  const [error, setError] = useState(null);
  const { language, toggleLanguage } = useLanguage();

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/pages/${id}.html`)
      .then(response => {
        if (!response.ok) {
          if (response.status === 404) {
            throw new Error(`Page not found for id: ${id}`);
          }
          throw new Error(`Failed to fetch the content for id: ${id}`);
        }
        return response.text();
      })
      .then(text => {
        const sanitizedText = DOMPurify.sanitize(text);
        setTextContent(sanitizedText);
        setError(null);
      })
      .catch(err => {
        setError(err.message);
      });
  }, [id]);

  return (
    <div>
      <Header/>
      {error ? (
        <div>
          <p>Sorry, the page could not be loaded. Please try again later.</p>
        </div>
      ) : (
        <div dangerouslySetInnerHTML={{ __html: textContent }} />
      )}
      <FooterComponent language={language}/>
      <button onClick={toggleLanguage}>
        {language === 'en' ? 'Switch to Spanish' : 'Switch to English'}
      </button>
    </div>
  );
}

export default InfoPage;
