

/**
  All of the routes for the Material Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

import Dashboard from "layouts/dashboard";
import CitizenDashboard from "layouts/citizendashboard";
import DepartmentDashboard from "layouts/departmentdashboard";
import Tables from "layouts/tables";
import StatusFailed from "layouts/statusfailed";
import StatusCompleted from "layouts/statuscompleted";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import CitizenSignIn from "layouts/authentication/citizen-sign-in/index";
import CitizenOtp from "layouts/authentication/citizen-sign-in/otp";
import DepartmentSignIn from "layouts/authentication/department-sign-in";
import SignUp from "layouts/authentication/sign-up";
import DisclaimerPage from './infopages/disclaimer';
import CopyrightPage from './infopages/copyrights';
import TermsOfUse from './infopages/termsofuse';
import Accessibility from './infopages/accessibility';
import Grievance from './infopages/grievance';

import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  }, 
  {
    type: "collapse",
    name: "Dashboard",
    key: "resident/dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/resident/dashboard",
    component: <CitizenDashboard />,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "department/dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/department/dashboard",
    component: <DepartmentDashboard />,
  },
  {
    type: 'collapse',
    name: 'Disclaimer',
    key: 'disclaimer',
    route: '/i/disclaimer',
    component: <DisclaimerPage />,
  },
  {
    type: "collapse",
    name: "Copyright Policy",
    key: "copyrights",
    route: "/i/copyrights",
    component: <CopyrightPage />,
  },
  {
    type: 'collapse',
    name: 'Terms of Use',
    key: 'termsofuse',
    route: '/i/termsofuse',
    component: <TermsOfUse />,
  },
  {
    type: 'collapse',
    name: 'Grievance',
    key: 'grievance',
    route: '/i/grievance',
    component: <Grievance />,
  },
  {
    type: 'collapse',
    name: 'Accessibility',
    key: 'accessibility',
    route: '/i/accessibility',
    component: <Accessibility />,
  },
  {
    type: "collapse",
    name: "Tables",
    key: "tables",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/tables",
    component: <Tables />,
  },
  {
    type: "collapse",
    name: "Status",
    key: "status",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/department/failed",
    component: <StatusFailed/>,
  },
  {
    type: "collapse",
    name: "Status",
    key: "status",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/department/completed",
    component: <StatusCompleted/>,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/resident/profile",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Department",
    key: "department",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/department",
    component: <DepartmentSignIn/>,
  },
  {
    type: "collapse",
    name: "Resident",
    key: "resident",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/resident",
    component: <CitizenSignIn  />,
  },
  {
    type: "collapse",
    name: "Resident Otp",
    key: "residentotp",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/resident/otp",
    component: <CitizenOtp  />,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/home",
    component: <SignUp />,
  },
];

export default routes;