import React, { useState, useEffect } from "react";
import { Modal, Box, Typography, IconButton, TextField, Button } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { Apiurl } from "../../config";
import CryptoJS from 'crypto-js';

const OTPModal = ({ open, handleClose, aadhaarNo, trnsCode }) => {
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [username, setUsername] = useState('');
  const [maskedAadhaar, setMaskedAadhaar] = useState('');
  const [dobyear, setDobyear] = useState('');
  const [gender, setGender] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [ip, setIp] = useState('');

  useEffect(() => {
    const fetchIp = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        setIp(response.data.ip);
      } catch (error) {
        console.error('Error fetching the IP address:', error);
      }
    };

    fetchIp();
  }, []);
  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };
  function encodeToBase64(plainText, secretKey) {
    const keyBytes = CryptoJS.SHA256(secretKey).toString(CryptoJS.enc.Hex).slice(0, 32);
    const key = CryptoJS.enc.Hex.parse(keyBytes);
    const iv = CryptoJS.enc.Hex.parse(keyBytes); // Use the same bytes for IV

    const encrypted = CryptoJS.AES.encrypt(plainText, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    return encrypted.toString(); // Base64 encoded by default
  }
  function decodeFromBase64(encryptedText, secretKey) {

    // Create a 16-byte key
    const keyBytes = CryptoJS.SHA256(secretKey).toString(CryptoJS.enc.Hex).slice(0, 32); // Use the first 16 bytes of the hash
    const key = CryptoJS.enc.Hex.parse(keyBytes);
    const iv = CryptoJS.enc.Hex.parse(keyBytes); // Use the same bytes for IV
  
    const decrypted = CryptoJS.AES.decrypt(encryptedText, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
  const handleResendOTP = async () => {
    try {
      const cleanedAadhaarNo = aadhaarNo.replace(/\s/g, '');
      const currentDate = new Date().toISOString().split('T')[0].replace(/-/g, '');
      const key = currentDate + ip;
      const encryptedAadhaarNo = encodeToBase64(cleanedAadhaarNo, key);
      // const response = await axios.get(Apiurl + 'sendotp/' + aadhaarNo.replace(/\s/g, ''));
      let response;
      if (window.location.href.includes("localhost")) {
          response = await axios.post(Apiurl + "sendotp/");
      } else {
          response = await axios.post(Apiurl + "sendotp/", {
          uid: encryptedAadhaarNo
        });
      }
      const { trns_code } = response.data;
      console.log('API Response:', response.data);
      navigate('/resident/otp', { state: { aadhaarNo, trns_code } });
    } catch (error) {
      console.error('Error sending OTP:', error);
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const cleanedAadhaarNo = aadhaarNo.replace(/\s/g, '');
      const currentDate = new Date().toISOString().split('T')[0].replace(/-/g, '');
      const key = currentDate + ip;
      const encryptedAadhaarNo = encodeToBase64(cleanedAadhaarNo, key);
      // const response = await axios.get(Apiurl + 'ekyc/otp/' + aadhaarNo.replace(/\s/g, '') + '/' + otp + '/' + trnsCode);
      let response;
      if (window.location.href.includes("localhost")) {
          response = await axios.post(Apiurl + "ekyc/otp/");
      } else {
          response = await axios.post(Apiurl + "ekyc/otp/", {
          uid: encryptedAadhaarNo,
          OTP: otp,
          trnsCode: trnsCode
        });
      }
      if (response.status === 200) {
        // If the API call is successful, update the success modal content and open it
        console.log(' ekyc API Response:', response.data);
        localStorage.setItem("Authorization", response.data.Authorization);
        console.log(response.data.Authorization);
        const decodedXML = atob(response.data.Pht);
        const photo = decodedXML;
        const currentYear = new Date().getFullYear();
        setUsername(response.data.Poi.name);
        let dob = response.data.Poi.dob;
        if (dob.includes('-')) {
          dob = dob.split('-')[2];
        }
        dob = parseInt(dob, 10);
        setDobyear(currentYear - dob);
        setGender(response.data.Poi.gender === 'M' ? 'Male' : response.data.Poi.gender === 'F' ? 'Female' : 'Transgender');
        let arrayBuffer;
  if (photo && photo.length) {
    arrayBuffer = new Uint8Array(photo.length);
    for (let i = 0; i < photo.length; i++) {
      arrayBuffer[i] = photo.charCodeAt(i);
    }
  }
  const blob = new Blob([arrayBuffer], { type: 'image/jpeg' });
  const imageUrl = URL.createObjectURL(blob);
        setImageUrl(imageUrl);
        
        setMaskedAadhaar(aadhaarNo ? `XXXX XXXX ${aadhaarNo.slice(-4)}` : '');

        // Open success modal
        setSuccessModalOpen(true);
      } else {
        setError(translations[language].invalidOtp);
      }
      handleClose();
    } catch (error) {
      setError('Invalid OTP. Please try again.');
    }
  };

  const handleCloseVerify = () => {
    setSuccessModalOpen(false);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            color: '#ffffff',
            p: 4,
            borderRadius: 2,
          }}
        >
          <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 8, right: 8 }}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            Enter OTP
          </Typography>
          <TextField
            fullWidth
            label="OTP"
            variant="outlined"
            value={otp}
            onChange={handleOtpChange}
            error={!!error}
            helperText={error}
            sx={{ mb: 2 }}
          />
          <Box display="flex" gap={2}>
            <Button variant="contained" sx={{ color: '#ffffff' }} onClick={handleVerifyOtp}>
              Verify OTP
            </Button>
            <Button variant="contained" sx={{ color: '#ffffff' }} onClick={handleResendOTP}>
              Resend OTP
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={successModalOpen}
        onClose={handleCloseVerify}
        aria-labelledby="success-modal-title"
        aria-describedby="success-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 1,
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
            }}
            onClick={handleCloseVerify}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="success-modal-title" variant="h6" component="h2">
            Successfully Verified
          </Typography>
          <Typography id="success-modal-description" sx={{ mt: 2 }}>
            Your details have been successfully verified.
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2 }}>
            <Box>
              <p><b>Name: </b>{username}</p>
              <p><b>Aadhaar No: </b>{maskedAadhaar}</p>
              <p><b>Age: </b>{dobyear}</p>
              <p><b>Gender: </b>{gender}</p>
            </Box>
            <img src={imageUrl} alt="Profile Picture" style={{ width: '150px', height: '150px', borderRadius: '50%', objectFit: 'fill' }} />
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default OTPModal;
