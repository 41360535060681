import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { LanguageProvider } from './LanguageContext';
import { MaterialUIControllerProvider } from "context";

const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  <BrowserRouter basename="/web">
    <MaterialUIControllerProvider>
      <LanguageProvider>
        <App />
      </LanguageProvider>
    </MaterialUIControllerProvider>
  </BrowserRouter>
);