import { createChatBotMessage } from 'react-chatbot-kit';
import GrievanceOptions from './components/GrievanceOptions'; // Add this line

const config = {
  initialMessages: [
    createChatBotMessage("Please select one below:", {
      widget: "grievanceOptions",
    }),
  ],
  widgets: [
    {
      widgetName: "grievanceOptions",
      widgetFunc: (props) => <GrievanceOptions {...props} />,
    },
  ],
  botName: 'Chatbot',
};

export default config;