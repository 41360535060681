import React from 'react';
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel';
import styled from 'styled-components';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import FirstBanner from 'assets/images/banner1.jpg';
import SecondBanner from 'assets/images/banner2.jpg';
import ThirdBanner from 'assets/images/banner3.jpg';
import FourthBanner from 'assets/images/banner4.jpg';
const StyledCarousel = styled(ResponsiveCarousel)`
  position: absolute;
  width: 100%;
  height: 100vh;

  .carousel .slide {
    height: 100vh;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const CarouselComponent = () => {

  return (
    <StyledCarousel showThumbs={false} autoPlay infiniteLoop interval={3000}>
      <div>
        <img src={FirstBanner} alt="FirstBanner" />
      </div>
      <div>
        <img src={SecondBanner} alt="SecondBanner" />
      </div>
      <div>
        <img src={ThirdBanner} alt="ThirdBanner" />
      </div>
      <div>
        <img src={FourthBanner} alt="FourthBanner" />
      </div>
    </StyledCarousel>
  );
};

export default CarouselComponent;
