import { useState } from "react";
// react-router-dom components
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { useLanguage } from '../../../LanguageContext';
import { translations } from "../translations";
import CarouselComponent from '../styledcarousel';
const SignInContainer = styled.div`
  position: relative;
  z-index: 1;
`;
function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const { language } = useLanguage();
  const handleSignIn = () => {
    navigate('/dashboard');
  };
  return (
    <>
       <CarouselComponent/>
      <SignInContainer>
        <BasicLayout>
          <Card>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center"
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                {translations[language].signIn}
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <MDBox component="form" role="form">
                <MDBox mb={2}>
                  <MDInput type="email" label={translations[language].email} fullWidth />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput type="password" label={translations[language].password} fullWidth />
                </MDBox>
                <MDBox display="flex" alignItems="center" ml={-1}>
                  <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    onClick={handleSetRememberMe}
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  >
                    {translations[language].rememberMe}
                  </MDTypography>
                </MDBox>
                <MDBox mt={4} mb={1}>
                  <MDButton variant="gradient" color="info" fullWidth onClick={handleSignIn}>
                    {translations[language].signInButton}
                  </MDButton>
                </MDBox>
                <MDBox mt={3} mb={1} textAlign="center">
                  <MDTypography variant="button" color="text">
                    {translations[language].dontHaveAccount}{' '}
                    <MDTypography
                      component={Link}
                      to="/home"
                      variant="button"
                      color="info"
                      fontWeight="medium"
                      textGradient
                    >
                      {translations[language].signUp}
                    </MDTypography>
                  </MDTypography>
                </MDBox>
              </MDBox>
            </MDBox>
          </Card>
        </BasicLayout>
      </SignInContainer>
    </>
  );
}
export default Basic;
