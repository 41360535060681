import React from "react";
import styled from "styled-components";
import { useLanguage } from '../LanguageContext';
import { translations } from "../layouts/authentication/translations";
import Header from '../layouts/authentication/header';
import FooterComponent from '../layouts/authentication/footer';
const MainContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  padding: 1rem;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
`;

 
const CopyrightPage = () => {
  const { language, toggleLanguage } = useLanguage();

  return (
    <>
      <Container>
      <Header />
        <MainContent>
        <div>
            <h3 style={{ textAlign: 'center' }}>{translations[language].privacyPolicy.title}</h3>
            <p>
              {translations[language].privacyPolicy.content}
            </p>
            <b>{translations[language].cookies.title}</b>
            <p>
              {translations[language].cookies.content}
            </p>
            <h4>{translations[language].hyperlinkPolicy.title}</h4>
            <b>{translations[language].hyperlinkPolicy.linksToExternal.title}</b>
            <p>
              {translations[language].hyperlinkPolicy.linksToExternal.content}
            </p>
            <b>{translations[language].hyperlinkPolicy.linksToSiteByOther.title}</b>
            <p>
              {translations[language].hyperlinkPolicy.linksToSiteByOther.content}
            </p>
          </div>
        </MainContent>
        <FooterComponent language={language} />
      </Container>
    </>
  );
};

export default CopyrightPage;
