import React from "react";
import styled from "styled-components";
import { useLanguage } from '../LanguageContext';
import { translations } from "../layouts/authentication/translations";
import Header from '../layouts/authentication/header';
import FooterComponent from '../layouts/authentication/footer';
const MainContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1; 
  padding: 1rem;
  text-align: center;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
`;

const DisclaimerPage = () => {
  const { language, toggleLanguage } = useLanguage();

  return (
    <>
      <Container>
      <Header />
        <MainContent>
          <div>
            <h1>{translations[language].disclaimer.title}</h1>
            <p>{translations[language].disclaimer.content}</p>
          </div>
        </MainContent>
        <FooterComponent language={language} />
      </Container>
    </>
  );
};

export default DisclaimerPage;
