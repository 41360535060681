import styled from "styled-components";
export const MainContent = styled.div`
  display: flex;
  align-items: center; /* Align content to the center vertically */
  flex-direction: column;
  flex-grow: 1; /* Grow to fill available space */
  padding: 1rem; /* Add padding for better spacing */
  // font-size: 1rem;
  p {
    margin-bottom: 1rem;
    text-align: left; /* Align text to the left */
    line-height: 1.5;
  }
  ol {
    margin-bottom: 1rem;
    padding-left: 1.5rem;
  }
  li {
    margin-bottom: 0.5rem;
  }
  h1 {
    // font-size: 2rem; /* Adjust the font size as needed */
    margin-bottom: 1rem;
  }
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Minimum height to fill the viewport */
  justify-content: space-between; /* Align children at the start and end */
`;
