import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Sidenav from "templates/Sidenav";
import Configurator from "templates/Configurator";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import routes from "routes";
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import InfoPage from "./infopage";
import { useLanguage } from './LanguageContext';
import { ToggleTheme } from './DarkThemeContext';
import { ModalProvider } from './ModalContext';
import ChatbotWrapper  from './components/ChatbotWrapper';
const App = () => {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor, transparentSidenav, whiteSidenav } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const { language } = useLanguage();
  const [darkMode, setDarkMode] = useState(false);
  const [fontSize, setFontSize] = useState(1); // Default font size in rem

  useEffect(() => {
    if (darkMode) {
      document.body.style.backgroundColor = '#333';
      document.body.style.color = '#fff';
    } else {
      document.body.style.backgroundColor = '#fff';
      document.body.style.color = '#000';
    }
    document.body.style.fontSize = `${fontSize}rem`; // Apply font size in rem
  }, [darkMode, fontSize]);

  const browserName = () => {
    const userAgent = navigator.userAgent;    
    if (userAgent.indexOf("Chrome") !== -1) {
      return "Google Chrome";
    } else if (userAgent.indexOf("Safari") !== -1) {
      return "Safari";
    } else if (userAgent.indexOf("Firefox") !== -1) {
      return "Mozilla Firefox";
    } else if (userAgent.indexOf("MSIE") !== -1 || userAgent.indexOf("Trident/") !== -1) {
      return "Internet Explorer";
    } else if (userAgent.indexOf("Edge") !== -1) {
      return "Microsoft Edge";
    } else {
      return "Unknown";
    }
  };
  
  const currentBrowser = browserName();
  
  useEffect(() => {
    document.cookie = `cookie=${currentBrowser}`;
  }, []);


  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const increaseFontSize = () => {
    setFontSize(prevSize => prevSize + 0.1);
  };

  const decreaseFontSize = () => {
    setFontSize(prevSize => Math.max(0.75, prevSize - 0.1));
  };

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }
      return null;
    });

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <ToggleTheme>
        <ModalProvider>
          <CssBaseline />
          {layout === "dashboard" && pathname !== "/home" && !pathname.includes("/i") && (
            <>
              <Sidenav
                  color={sidenavColor}
                  brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                  brandName="TamilNadu eKYC"
                  routes={routes}
                  onMouseEnter={handleOnMouseEnter}
                  onMouseLeave={handleOnMouseLeave}
                />
              <Configurator />
            </>
          )}
           {/* <ChatbotWrapper  /> */}
          {layout === "vr" && <Configurator />}
          <Routes>
            <Route path="/i/:id" element={<InfoPage />} />
            {getRoutes(routes)}
            <Route path="*" element={<Navigate to="/home" />} />
          </Routes>        
        </ModalProvider>
      </ToggleTheme>
    </ThemeProvider>
  );
}

export default App;