// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Dashboard.css */
.dashboard-container {
  padding: 20px;
}

.aadhaar-input-container {
  margin-bottom: 20px;
}

.aadhaar-input-container label {
  margin-right: 10px;
  font-weight: bold;
}

.aadhaar-input-container input {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.allocated-data-container h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.allocated-data-container table {
  width: 100%;
  border-collapse: collapse;
}

.allocated-data-container th,
.allocated-data-container td {
  padding: 12px;
  text-align: left;
  border: 1px solid #ddd;
}

.allocated-data-container th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.allocated-data-container tr:nth-child(even) {
  background-color: #f9f9f9;
}
.dataTable {
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden; /* Ensure table doesn't overflow with borders */
}
`, "",{"version":3,"sources":["webpack://./src/layouts/statusfailed/Dashboard.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;EACE,aAAa;AACf;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;EACnB,eAAe;EACf,WAAW;AACb;;AAEA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;;EAEE,aAAa;EACb,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;AAC3B;AACA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB,EAAE,+CAA+C;AACnE","sourcesContent":["/* Dashboard.css */\r\n.dashboard-container {\r\n  padding: 20px;\r\n}\r\n\r\n.aadhaar-input-container {\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.aadhaar-input-container label {\r\n  margin-right: 10px;\r\n  font-weight: bold;\r\n}\r\n\r\n.aadhaar-input-container input {\r\n  padding: 8px;\r\n  border-radius: 4px;\r\n  border: 1px solid #ccc;\r\n}\r\n\r\n.allocated-data-container h2 {\r\n  margin-bottom: 20px;\r\n  font-size: 24px;\r\n  color: #333;\r\n}\r\n\r\n.allocated-data-container table {\r\n  width: 100%;\r\n  border-collapse: collapse;\r\n}\r\n\r\n.allocated-data-container th,\r\n.allocated-data-container td {\r\n  padding: 12px;\r\n  text-align: left;\r\n  border: 1px solid #ddd;\r\n}\r\n\r\n.allocated-data-container th {\r\n  background-color: #f4f4f4;\r\n  font-weight: bold;\r\n}\r\n\r\n.allocated-data-container tr:nth-child(even) {\r\n  background-color: #f9f9f9;\r\n}\r\n.dataTable {\r\n  border: 1px solid #ccc;\r\n  border-radius: 5px;\r\n  overflow: hidden; /* Ensure table doesn't overflow with borders */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
