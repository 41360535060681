import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import DashboardLayout from "templates/LayoutContainers/DashboardLayout";
import DashboardNavbar from "templates/Navbars/DashboardNavbar";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';

const DataTable = () => {
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [perPage, setPerPage] = useState(10); // Number of items per page
  const [displayPages, setDisplayPages] = useState([]);
  const [searchTerm, setSearchTerm] = useState(''); // State for search term
  const [totalRecords, setTotalRecords] = useState(0); // Total number of records
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc'); // 'asc' or 'desc'
  const observer = useRef();
  const [selectedFormat, setSelectedFormat] = useState('');
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`https://jsonplaceholder.typicode.com/photos`);
        setAllData(response.data);
        setTotalRecords(response.data.length);
        setTotalPages(Math.ceil(response.data.length / perPage));
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
    fetchData();
  }, [perPage]);

  useEffect(() => {
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    const paginatedData = allData.slice(startIndex, endIndex);
    setData(paginatedData);
  }, [page, perPage, allData]);

  useEffect(() => {
    if (totalPages <= 5) {
      setDisplayPages(Array.from({ length: totalPages }, (_, index) => index + 1));
    } else {
      if (page <= 3) {
        setDisplayPages([1, 2, 3, 4, 5]);
      } else if (page >= totalPages - 2) {
        setDisplayPages([totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages]);
      } else {
        setDisplayPages([page - 2, page - 1, page, page + 1, page + 2]);
      }
    }
  }, [page, totalPages]);

  const handlePaginationClick = (pageNumber) => {
    setPage(pageNumber);
  };

  const handlePerPageChange = (event) => {
    setPerPage(Number(event.target.value));
    setPage(1); // Reset page to 1 when perPage changes
  };

  // Filter data based on search term across all records
  const filteredData = allData.filter((item) =>
    Object.values(item).some((value) => value.toString().toLowerCase().includes(searchTerm.trim().toLowerCase()))
  );


  const handleSort = (column) => {
    if (column === 'S.No') {
      setSortColumn(null);
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      if (column === sortColumn) {
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
      } else {
        setSortColumn(column);
        setSortDirection('asc');
      }
    }
  
    // Disable sorting on other columns
    const headers = Object.keys(allData[0] || {});
    headers.forEach((header) => {
      if (header !== column) {
        document.getElementById(`sort-${header}`).style.pointerEvents = 'none';
        document.getElementById(`sort-${header}`).style.opacity = '0.5';
      } else {
        document.getElementById(`sort-${header}`).style.pointerEvents = 'auto';
        document.getElementById(`sort-${header}`).style.opacity = '1';
      }
    });
  };
  
  const handleExport = (format) => {
    // Logic to handle export based on selected format
    switch (format) {
      case 'CSV':
        handleDownloadCSV();
        break;
      case 'PDF':
        handleDownloadPDF();
        break;
      case 'Excel':
        handleDownloadExcel();
        break;
      case 'AllCSV':
        handleDownloadAllCSV();
        break;
      case 'AllPDF':
        handleDownloadAllPDF();
        break;
      case 'AllExcel':
        handleDownloadAllExcel();
        break;
      default:
        break;
    }
  };
  // Sort data based on the selected column and direction
  const sortedData = filteredData.slice().sort((a, b) => {
    if (sortColumn === null) { // Sort by S.No
      const indexA = allData.indexOf(a);
      const indexB = allData.indexOf(b);
      return sortDirection === 'asc' ? indexA - indexB : indexB - indexA;
    } else {
      const valA = a[sortColumn];
      const valB = b[sortColumn];
      if (valA < valB) {
        return sortDirection === 'asc' ? -1 : 1;
      }
      if (valA > valB) {
        return sortDirection === 'asc' ? 1 : -1;
      }
      return 0;
    }
  });

  // Paginate sorted data
  const startIndex = (page - 1) * perPage;
  const paginatedSortedData = sortedData.slice(startIndex, startIndex + perPage);


  const handleDownloadCSV = () => {
    const headers = Object.keys(allData[0] || {});
    const csv = [
      headers.join(','), 
      ...sortedData.map((item) => headers.map((header) => item[header]).join(',')),
    ].join('\n');
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'data.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const handleDownloadPDF = () => {
    const headers = Object.keys(allData[0] || {});
    const doc = new jsPDF();
    doc.autoTable({
      head: [headers],
      body: sortedData.map((item) => headers.map((header) => item[header])),
    });
    doc.save('data.pdf');
  };

  const handleDownloadExcel = () => {
    const headers = Object.keys(allData[0] || {});
    const worksheetData = [
      headers, // Add headers as the first row
      ...sortedData.map((item) => headers.map((header) => item[header])), // Map data rows to header order
    ];
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData); // Use aoa_to_sheet to ensure headers are properly included
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'data');
    XLSX.writeFile(workbook, 'data.xlsx');
  };

  const handleDownloadAllCSV = async () => {
    try {
      const headers = Object.keys(allData[0] || {});
      const csv = [
        headers.join(','), 
        ...allData.map((item) => headers.map((header) => item[header]).join(',')),
      ].join('\n');
      const blob = new Blob([csv], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'all_data.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading all data:', error);
    }
  };

  const handleDownloadAllPDF = async () => {
    try {
      const headers = Object.keys(allData[0] || {});
      const doc = new jsPDF();
      doc.autoTable({
        head: [headers],
        body: allData.map((item) => headers.map((header) => item[header])),
      });
      doc.save('all_data.pdf');
    } catch (error) {
      console.error('Error downloading all data:', error);
    }
  };

  const handleDownloadAllExcel = async () => {
    try {
      const headers = Object.keys(allData[0] || {});
      const worksheetData = [
        headers, // Add headers as the first row
        ...allData.map((item) => headers.map((header) => item[header])), // Map data rows to header order
      ];
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData); // Use aoa_to_sheet to ensure headers are properly included
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'data');
      XLSX.writeFile(workbook, 'all_data.xlsx');
    } catch (error) {
      console.error('Error downloading all data:', error);
    }
  };

  // Dynamically generate table headers from the keys of the first data item
  const headers = data.length > 0 ? Object.keys(data[0]) : [];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div>
        <div className="download-options" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
          <h2>Sample Title</h2>
          <div className="search-filter col-md-5 col-lg-4 col-xl-3">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="form-control"
              style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
            />
          </div>
        </div>

        <table className="table table-striped">
          <thead>
            <tr>
            <th onClick={() => handleSort('S.No')}>
              <span>S.No</span> {sortColumn === 'S.No' && (sortDirection === 'asc' ? '▼' : '▲')}
            </th>
              {headers.map((header) => (
                <th id={`sort-${header}`} onClick={() => handleSort(header)} style={{ position: 'relative' }}>
                <span style={{ textTransform: 'capitalize', fontSize: 'larger' }}>{header}</span>
                <span style={{ position: 'relative'}}>
                  {sortDirection === 'asc' ? '▼' :'▲' }
                </span>
              </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {paginatedSortedData.map((item, index) => (
              <tr key={item.id}>
                <td>{startIndex + index + 1}</td>
                {headers.map((header) => (
                  <td key={header}>{item[header]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <div className="row">
          <div className="col-md-6">
            <nav aria-label="Page navigation">
              <ul className="pagination">
                <li className={`paginate_button page-item previous ${page === 1 ? 'disabled' : ''}`}>
                  <a href="#" className="page-link" onClick={() => handlePaginationClick(page - 1)}>Previous</a>
                </li>
                {displayPages.map((pageNumber) => (
                  <li key={pageNumber} className={`paginate_button page-item ${pageNumber === page ? 'active' : ''}`}>
                    <a href="#" className="page-link" onClick={() => handlePaginationClick(pageNumber)}>{pageNumber}</a>
                  </li>
                ))}
                <li className={`paginate_button page-item next ${page === totalPages ? 'disabled' : ''}`}>
                  <a href="#" className="page-link" onClick={() => handlePaginationClick(page + 1)}>Next</a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="col-md-6 d-flex justify-content-end align-items-center">
            <div className="record-count-container">
              <span>Showing {startIndex + 1} to {Math.min(startIndex + perPage, filteredData.length)} of {totalRecords} records</span>
              <select value={perPage} onChange={handlePerPageChange}>
                <option value={10}>10 / page</option>
                <option value={50}>50 / page</option>
                <option value={100}>100 / page</option>
                <option value={250}>250 / page</option>
              </select>
            </div>
            <div className="download-buttons-container" style={{ display: 'flex' }}>
              <select onChange={(e) => handleExport(e.target.value)}>
                <option value="" disabled>Select</option>
                <option value="CSV">CSV</option>
                <option value="PDF">PDF</option>
                <option value="Excel">Excel</option>
                <option value="AllCSV">All as CSV</option>
                <option value="AllPDF">All as PDF</option>
                <option value="AllExcel">All as Excel</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default DataTable;
