import React, { useState, useContext } from "react";
import styled, { keyframes } from "styled-components";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import FirstBanner from "assets/images/banner1.jpg";
import SecondBanner from "assets/images/banner2.jpg";
import ThirdBanner from "assets/images/banner3.jpg";
import FourthBanner from "assets/images/banner4.jpg";
import { NavLink } from "react-router-dom";
import { useLanguage } from '../../../LanguageContext';
import { translations } from "../translations";
import Header from '../header';
import FooterComponent from '../footer';
import { ThemeContext } from '../../../DarkThemeContext';
import ReportsLineChart from "templates/Charts/LineCharts/ReportsLineChart";
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";
import ReportsBarChart from "templates/Charts/BarCharts/ReportsBarChart";
import DOMPurify from 'dompurify';
const slideInLeft = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const NestedContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top:3rem;
`;

const ChartsColumn = styled.div`
  flex: 1;
  margin-right: 1rem; /* Add space between columns */
  @media (max-width: 768px) {
    flex: 100%; /* Full width on smaller screens */
    margin-right: 0; /* Reset margin on smaller screens */
  }
`;
const MainContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: #f8f9fa; /* Light background color */
  padding: 1.25rem;
`;

const DepartmentColumn = styled.div`
  flex: 1;
  padding: 1.25rem;
  margin-left: 1.25rem;
  @media (max-width: 768px) {
    flex: 100%; /* Full width on smaller screens */
  }
`;

const CitizenColumn = styled.div`
  flex: 1;
  padding: 1.25rem;
  margin-left: 1.25rem;
  @media (max-width: 768px) {
    flex: 100%; /* Full width on smaller screens */
  }
`;
const StyledCarousel = styled(Carousel)`
  .carousel .slide {
    height: 31.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 3.125rem;
  }
  .carousel .slide img {
    height: auto;
    width: 100%;
    object-fit: contain;
    object-position: center;
    border-radius: 8px; /* Rounded corners for images */
  }
`;

const CardTitle = styled.h5`
  color: #0079c1;
`;

const Button = styled.button`
  background-color: #0079c1;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  margin-top: 1rem;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #005f8a;
  }
  .nav-link {
    color: white;
    text-decoration: none;
  }
`;

const CitizenList = styled.div`
  animation: ${slideInLeft} 2s ease-in;
`;

const DepartmentList = styled.div`
  animation: ${slideInLeft} 2s ease-in;
`;

const SignUp = () => {
  const { language } = useLanguage();
  const { darkMode, toggleDarkMode, increaseFontSize, decreaseFontSize } = useContext(ThemeContext);
  const { sales, tasks } = reportsLineChartData;
  const [showMenu, setShowMenu] = useState(false);
  const sanitizedcitizenList = DOMPurify.sanitize(translations[language].citizenList);
  const sanitizeddepartmentList = DOMPurify.sanitize(translations[language].departmentList);
  const closeMenuOnMobile = () => {
    if (window.innerWidth <= 1150) {
      setShowMenu(false);
    }
  }; 

  return (
    <>
      <Header
        darkMode={darkMode}
        toggleDarkMode={toggleDarkMode}
        increaseFontSize={increaseFontSize}
        decreaseFontSize={decreaseFontSize}
      />
      <StyledCarousel showThumbs={false} autoPlay infiniteLoop interval={3000}>
        <div>
          <img src={FirstBanner} alt="firstbanner" />
        </div>
        <div>
          <img src={SecondBanner} alt="secondbanner" />
        </div>
        <div>
          <img src={ThirdBanner} alt="thirdbanner" />
        </div>
        <div>
          <img src={FourthBanner} alt="fourthbanner" />
        </div>        
      </StyledCarousel>
          {/* <NestedContentContainer>
            <ChartsColumn>
              <ReportsBarChart
                color="info"
                title="District wise (Top 10)"
                description="Last Campaign Performance"
                date="campaign sent 2 days ago"
                chart={reportsBarChartData}
              />
            </ChartsColumn>
            <ChartsColumn>
              <ReportsLineChart
                color="dark"
                title="Application wise"
                description="Last Campaign Performance"
                date="just updated"
                chart={tasks}
              />
            </ChartsColumn>
            <ChartsColumn>
              <ReportsLineChart
                color="success"
                title="Modality Wise (Top 10)"
                description={
                  <>
                    (<strong>+15%</strong>) increase.
                  </>
                }
                date="updated 4 min ago"
                chart={sales}
              />
            </ChartsColumn>
          </NestedContentContainer> */}
      <MainContent>
        <CitizenColumn>
          <div className="card">
            <div className="card-body">
              <CardTitle className="card-title">{translations[language].citizen}</CardTitle>
              <CitizenList dangerouslySetInnerHTML={{ __html: sanitizedcitizenList }}></CitizenList>
              <Button>
                <NavLink to="/resident" className="nav-link" onClick={closeMenuOnMobile}>
                  {translations[language].register} / {translations[language].login}
                </NavLink>
              </Button>
            </div>
          </div>
        </CitizenColumn>
        <DepartmentColumn>
          <div className="card">
            <div className="card-body">
              <CardTitle className="card-title">{translations[language].department}</CardTitle>
              <DepartmentList dangerouslySetInnerHTML={{ __html: sanitizeddepartmentList }}></DepartmentList>
              <Button>
                <NavLink to="/department" className="nav-link" onClick={closeMenuOnMobile}>
                  {translations[language].login}
                </NavLink>
              </Button>
            </div>
          </div>
        </DepartmentColumn>
      </MainContent>
      <FooterComponent language={language} />
    </>
  );
};

export default SignUp;