import DashboardLayout from "templates/LayoutContainers/DashboardLayout";
import DashboardNavbar from "templates/Navbars/DashboardNavbar";
import { useLocation } from "react-router-dom";
import { Box, Typography, Grid, Avatar, Paper, CircularProgress } from "@mui/material";

function Overview() {
  const { state } = useLocation();
  const { aadhaarNo, maskedAadhaar, username, dobyear, gender, photo } = state || {};

  // Check if photo is available and create image URL
  let imageUrl = null;
  if (photo) {
    try {
      const arrayBuffer = new Uint8Array(photo.length);
      for (let i = 0; i < photo.length; i++) {
        arrayBuffer[i] = photo.charCodeAt(i);
      }
      const blob = new Blob([arrayBuffer], { type: 'image/jpeg' });
      imageUrl = URL.createObjectURL(blob);
    } catch (error) {
      console.error('Error creating image URL:', error);
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box sx={{ p: 3 }}>
        <Typography variant="h5" gutterBottom>
          My Profile
        </Typography>
        <Paper elevation={3} sx={{ p: 2 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <strong>Name:</strong> {username || 'Not available'}
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <strong>Masked Aadhaar:</strong> {maskedAadhaar || 'Not available'}
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <strong>Year of Birth:</strong> {dobyear || 'Not available'}
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <strong>Gender:</strong> {gender || 'Not available'}
              </Typography>
            </Grid>
            {photo && (
              <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {imageUrl ? (
            <img src={imageUrl} alt="Profile Picture" style={{ width: '150px', height: '150px', borderRadius: '50%', objectFit: 'fill' }} />
          ) : (
                  <CircularProgress />
                )}
              </Grid>
            )}
          </Grid>
        </Paper>
      </Box>
    </DashboardLayout>
  );
}

export default Overview;
