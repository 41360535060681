import React from "react";
import { useLanguage } from '../LanguageContext';
import { translations } from "../layouts/authentication/translations";
import { MainContent, Container } from "./styledComponents";
import Header from '../layouts/authentication/header';
import FooterComponent from '../layouts/authentication/footer';
import DOMPurify from 'dompurify';

const TermsOfUse = () => {
    const { language } = useLanguage();
    const sanitizedFourthContent = DOMPurify.sanitize(translations[language].termsofuse.fourthContent);

    return (
        <Container>
            <Header />
            <MainContent>
                <div>
                    <h1 style={{ textAlign: 'center' }}>{translations[language].termsofuse.title}</h1>
                    <p>{translations[language].termsofuse.firstContent}</p>
                    <p>{translations[language].termsofuse.secondContent}</p>
                    <p>{translations[language].termsofuse.thirdContent}</p>
                    <div dangerouslySetInnerHTML={{ __html: sanitizedFourthContent }}></div>
                    <p>{translations[language].termsofuse.fifthContent}</p>
                    <p>{translations[language].termsofuse.sixthContent}</p>
                    <p>{translations[language].termsofuse.seventhContent}</p>
                </div>
            </MainContent>
            <FooterComponent language={language} />
        </Container>
    );
};

export default TermsOfUse;
