import React, { useEffect, useState } from "react";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import { Box, Typography } from "@mui/material";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import MDTypography from "components/MDTypography";
import SidenavCollapse from "templates/Sidenav/SidenavCollapse";
import SidenavRoot from "templates/Sidenav/SidenavRoot";
import sidenavLogoLabel from "templates/Sidenav/styles/sidenav";
import MDButton from "components/MDButton";
import axios from "axios";
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";
import { useModal } from "../../ModalContext";
import { Apiurl } from "../../config";
function Sidenav({ color, brand, brandName, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const location = useLocation();
  const navigate = useNavigate();
  const [menuItems, setMenuItems] = useState([]);
  const collapseName = location.pathname.replace("/", "");
  const { openModal } = useModal();
  let textColor;

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);
  
  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  useEffect(() => {
    let menuItemsPromise;
    if (location.pathname.includes("/resident")) {
      // menuItemsPromise = axios.get(Apiurl+"get/menu/ekycuser")
      //   .then(response => response.data)
      //   .catch(error => {
      //     console.error("Error fetching resident json:", error);
      //     return [];
      //   });
      setMenuItems([]);
    } else if (location.pathname.includes("/department")) {
      menuItemsPromise = axios.get(Apiurl + "get/menu/department")
        .then(response => response.data)
        .catch(error => {
          console.error("Error fetching department json:", error);
          return [];
        });
    }

    if (menuItemsPromise) {
      menuItemsPromise.then(items => {
        setMenuItems(items);
      });
    }
  }, [location.pathname]);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = menuItems.map(({ type, name, icon, title, noCollapse, key, href, route }) => {
    let returnValue;

    if (type === "collapse") {
      returnValue = href ? (
        <Link
          href={href}
          key={key}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavCollapse
            name={name}
            icon={icon}
            active={key === collapseName}
            noCollapse={noCollapse}
          />
        </Link>
      ) : (
        <NavLink key={key} to={route}>
          <SidenavCollapse name={name} icon={icon} active={key === collapseName} />
        </NavLink>
      );
    } else if (type === "title") {
      returnValue = (
        <MDTypography
          key={key}
          color={textColor}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </MDTypography>
      ); 
    } else if (type === "divider") {
      returnValue = (
        <Divider
          key={key}
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
      );
    }

    return returnValue;
  });
  const handleHome = () => {
    // Check the current location path and navigate accordingly
    if (location.pathname.includes("resident")) {
      navigate("/resident/dashboard", { state: location.state });
    } else if (location.pathname.includes("department")) {
      navigate("/department/dashboard");
    }
  };
  const HandleProfile = () => {
    console.log(location.state);
    navigate("/resident/profile", { state: location.state })
  };
  const handleLogout = () => {
    localStorage.removeItem("Authorization");
    localStorage.removeItem("USER");
    localStorage.removeItem("TOKEN");
    navigate("/home");
  };
  
  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox display="flex" alignItems="center">
          {/* {brand && <MDBox component="img" src={brand} alt="Brand" width="2rem" />} */}
          <MDBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDTypography  variant="button" fontWeight="medium" color={textColor}>
              <h4>{brandName ? brandName : "Tamil Nadu eKYC"}</h4>
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <MDBox display="flex" justifyContent="center" my={2}>
      <Box display="flex" flexDirection="column" alignItems="center" mx={2} onClick={handleHome}>
        <HomeIcon sx={{ cursor: "pointer", mx: 1, color: textColor }} title="Home"/>
        <Typography variant="caption">Home</Typography>
      </Box>
      {location.pathname.includes("resident") && (
      <Box display="flex" flexDirection="column" alignItems="center" mx={2} onClick={HandleProfile} >
        <PersonIcon sx={{ cursor: "pointer", mx: 1, color: textColor }} title="Profile"/>
        <Typography variant="caption">Profile</Typography>
      </Box>
      )}
      <Box display="flex" flexDirection="column" alignItems="center" onClick={handleLogout}>
        <LogoutIcon sx={{ cursor: "pointer", mx: 1, color: textColor }} title="Log Out" />
        <Typography variant="caption">Log Out</Typography>  
      </Box>
    </MDBox>
      <List>{renderRoutes}</List>
      {location.pathname.includes("resident") && (
      <MDBox display="flex" justifyContent="center" my={2}>
        <MDButton
          variant="contained"
          onClick={openModal}
          style={{ textTransform: 'none', backgroundColor: "green", color: "#ffffff" }}
          title="Perform eKYC"
        >
          PERFORM eKYC
        </MDButton>
      </MDBox>
      )}


    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),

};

export default Sidenav;
