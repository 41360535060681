import React, { useState, useEffect } from "react";
import axios from "axios";
import DashboardLayout from "templates/LayoutContainers/DashboardLayout";
import { Box, Typography, Button, Modal, IconButton } from "@mui/material";
import DashboardNavbar from "templates/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import { Apiurl } from "../../config";
import "./Dashboard.css";
import { useModal } from "../../ModalContext";
import CloseIcon from '@mui/icons-material/Close';
import { useLanguage } from '../../LanguageContext';
import { translations } from "../authentication/translations";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import OTPModal from "../citizendashboard/otpmodel";
import DataTable from "react-data-table-component";
import { TextField } from "@mui/material";
import CryptoJS from 'crypto-js';
import { useNavigate } from "react-router-dom";
function Dashboard() {
  const [aadhaarNumber, setAadhaarNumber] = useState("");
  const [allocatedData, setAllocatedData] = useState([]);
  const [summaryData, setSummaryData] = useState({ Total: 0, Completed: 0, Failed: 0 });
  const { isModalOpen, closeModal, openModal } = useModal();
  const [isConsentGiven, setIsConsentGiven] = useState(false);
  const [isFingerprintChecked, setIsFingerprintChecked] = useState(true);
  const [isIrisChecked, setIsIrisChecked] = useState(false);
  const [isFaceChecked, setIsFaceChecked] = useState(false);
  const [isOTPChecked, setIsOTPChecked] = useState(false);
  const { language, toggleLanguage } = useLanguage();
  const [selectedUser, setSelectedUser] = useState(null);
  const [currentXML, setCurrentXML] = useState("");
  const [currentURL, setCurrentURL] = useState("");
  const [currentDevice, setCurrentDevice] = useState("");
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [trnsCode, setTrnsCode] = useState(null);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [aadhaarNo, setAadhaarNo] = useState('');
  const [aadhaarError, setAadhaarError] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [username, setUsername] = useState("");
  const [dobyear, setDobyear] = useState("");
  const [maskedaadhaar, setMaskedaadhaar] = useState("");
  const [gender, setGender] = useState("");
  const [ip, setIp] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    const user = localStorage.getItem("USER");
    const token = localStorage.getItem("TOKEN");
    if (!token || !user) {
      navigate("/home");
    }
  }, [navigate]);
  useEffect(() => {
    const fetchIp = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        setIp(response.data.ip);
      } catch (error) {
        console.error('Error fetching the IP address:', error);
      }
    };

    fetchIp();
  }, []);
  // Verhoeff algorithm implementation
  const d = [
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    [1, 2, 3, 4, 0, 6, 7, 8, 9, 5],
    [2, 3, 4, 0, 1, 7, 8, 9, 5, 6],
    [3, 4, 0, 1, 2, 8, 9, 5, 6, 7],
    [4, 0, 1, 2, 3, 9, 5, 6, 7, 8],
    [5, 9, 8, 7, 6, 0, 4, 3, 2, 1],
    [6, 5, 9, 8, 7, 1, 0, 4, 3, 2],
    [7, 6, 5, 9, 8, 2, 1, 0, 4, 3],
    [8, 7, 6, 5, 9, 3, 2, 1, 0, 4],
    [9, 8, 7, 6, 5, 4, 3, 2, 1, 0]
  ];

  const p = [
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    [1, 5, 7, 6, 2, 8, 3, 0, 9, 4],
    [5, 8, 0, 3, 7, 9, 6, 1, 4, 2],
    [8, 9, 1, 6, 0, 4, 3, 5, 2, 7],
    [9, 4, 5, 3, 1, 2, 6, 8, 7, 0],
    [4, 2, 8, 6, 5, 7, 3, 9, 0, 1],
    [2, 7, 9, 3, 8, 0, 6, 4, 1, 5],
    [7, 0, 4, 6, 9, 1, 3, 2, 5, 8]
  ];

  const inv = [0, 4, 3, 2, 1, 5, 6, 7, 8, 9];
  function validateAadhaarNumber(number) {
    let c = 0;
    const invertedArray = number.split('').map(Number).reverse();

    for (let i = 0; i < invertedArray.length; i++) {
      c = d[c][p[(i % 8)][invertedArray[i]]];
    }

    return c === 0;
  }

  function formatAadhaarNumber(value) {
    return value.replace(/\D/g, '').replace(/(\d{4})(?=\d)/g, '$1 ');
  }
  const handleCloseVerify = () => {
    setSuccessModalOpen(false);
  };
  const handleNo = () => {
    closeModal();
  };
  useEffect(() => {
    handleFingerprintClick();
  }, []);
  const token = localStorage.getItem("TOKEN");
  const headers = {
    Authorization: `Bearer ${token}`
  };
  function encodeToBase64(plainText, secretKey) {
    const keyBytes = CryptoJS.SHA256(secretKey).toString(CryptoJS.enc.Hex).slice(0, 32);
    const key = CryptoJS.enc.Hex.parse(keyBytes);
    const iv = CryptoJS.enc.Hex.parse(keyBytes); // Use the same bytes for IV

    const encrypted = CryptoJS.AES.encrypt(plainText, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    return encrypted.toString(); // Base64 encoded by default
  }
  function decodeFromBase64(encryptedText, secretKey) {

    // Create a 16-byte key
    const keyBytes = CryptoJS.SHA256(secretKey).toString(CryptoJS.enc.Hex).slice(0, 32); // Use the first 16 bytes of the hash
    const key = CryptoJS.enc.Hex.parse(keyBytes);
    const iv = CryptoJS.enc.Hex.parse(keyBytes); // Use the same bytes for IV
  
    const decrypted = CryptoJS.AES.decrypt(encryptedText, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
  const fetchAllocatedData = async () => {
    try {
      let response;
      if (window.location.href.includes("localhost")) {
        response = await axios.get(Apiurl + "get/allocated/completed");
      } else {
        response = await axios.get(Apiurl + "get/allocated/completed", { headers });
      }
      const uniqueIds = new Set();
      const newData = response.data.data.filter(item => {
        if (uniqueIds.has(item.id)) {
          return false; // Filter out duplicates
        } else {
          uniqueIds.add(item.id);
          return true;
        }
      });
      setAllocatedData(newData);
      setFilteredData(newData);
      console.log(filteredData);
    } catch (error) {
      console.error("Error fetching allocated data:", error);
    }
  };
  const fetchSummaryData = async () => {
    try {
      let response;
      if (window.location.href.includes("localhost")) {
        response = await axios.get(Apiurl + "get/summary");
      } else {
        response = await axios.get(Apiurl + "get/summary", { headers });
      }
      setSummaryData(response.data);
    } catch (error) {
      console.error("Error fetching summary data:", error);
    }
  };
  const handleSort = (column, sortDirection) => {
    const sortedData = [...filteredData].sort((a, b) => {
      const aField = a[column.selector] ? a[column.selector].toString().toLowerCase() : '';
      const bField = b[column.selector] ? b[column.selector].toString().toLowerCase() : '';

      if (sortDirection === 'asc') {
        return aField.localeCompare(bField);
      } else {
        return bField.localeCompare(aField);
      }
    });
    setFilteredData(sortedData);
  };
  useEffect(() => {
    fetchAllocatedData();
    fetchSummaryData();
  }, []);
  useEffect(() => {
    const results = allocatedData.filter(item => {
      const uuid = item.uuid ? String(item.uuid).toLowerCase() : '';
      return (
        item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        uuid.includes(searchTerm.toLowerCase()) ||
        item.dob.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.gender.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.address.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
    setFilteredData(results);
  }, [searchTerm, allocatedData]);
  const handleCloseOTPModal = () => {
    setOtpModalOpen(false);
  };
  const columns = [
    { name: "NAME", selector: row => row.name, sortable: true },
    {
      name: "UUID",
      selector: row => {
        const uuid = row.uuid ? `${'X'.repeat(row.uuid.toString().length - 4)}${row.uuid.toString().slice(-4)}` : '';
        return uuid;
      },
      sortable: true
    },
    { name: "DOB", selector: row => row.dob, sortable: true },
    { name: "GENDER", selector: row => row.gender, sortable: true },
    { name: "ADDRESS", selector: row => row.address, sortable: true },
    {
      name: "ACTION",
      cell: row => (
        <MDButton
          variant="contained"
          onClick={() => handlePerformEkycClick(row)}
          style={{ textTransform: 'none', backgroundColor: 'green', color: '#ffffff' }}
        >
          PERFORM eKYC
        </MDButton>
      )
    }
  ];
  const handleFingerprintClick = async () => {
    setIsFingerprintChecked(true);
    setIsOTPChecked(false);
    setIsIrisChecked(false);
    const XML = '<?xml version="1.0"?> <PidOptions ver="1.0"> <Opts fCount="1" fType="2" iCount="0" pCount="0" format="0" pidVer="2.0" timeout="10000" wadh="RZ+k4w9ySTzOibQdDHPzCFqrKScZ74b3EibKYy1WyGw=" posh="UNKNOWN" env="P" /> <CustOpts><Param name="mantrakey" value="undefined" /></CustOpts> </PidOptions>';
    setCurrentXML(XML);
    const urls = ["http://127.0.0.1:11100/", "http://127.0.0.1:11101/"];

    const checkURL = async (url, method = 'RDSERVICE') => {
      try {
        const response = await fetch(url, {
          method: method,
        });
        if (response.ok) {
          return url;
        }
      } catch (error) {
        console.error(`Error checking URL ${url}:`, error);
      }
      return null;
    };

    const setAvailableURL = async () => {
      for (const url of urls) {
        const result = await checkURL(url);
        if (result) {
          return result;
        }
      }
      return null;
    };

    const availableURL = await setAvailableURL();
    if (availableURL) {
      setCurrentURL(availableURL);
    } else {
      console.error("No available URL found for the device.");
    }

    setCurrentDevice("ekyc/fp");
  };

  const handleIrisClick = async () => {
    setIsIrisChecked(true);
    setIsOTPChecked(false);
    setIsFingerprintChecked(false);
    const XML = '<?xml version="1.0"?> <PidOptions ver="1.0"> <Opts fCount="0" fType="0" iCount="1" iType="1" pCount="0" format="0"   pidVer="2.0" timeout="10000" wadh="T9nIfCslZnsX4pR6o1CzBDHp7MUiYne6QySOZvmB3Rk=" posh="UNKNOWN" env="P" /> <CustOpts><Param name="mantrakey" value="undefined" /></CustOpts> </PidOptions>';
    setCurrentXML(XML);
    const urls = ["http://127.0.0.1:11100/", "http://127.0.0.1:11101/"];

    const checkURL = async (url, method = 'RDSERVICE') => {
      try {
        const response = await fetch(url, {
          method: method,
        });
        if (response.ok) {
          return url;
        }
      } catch (error) {
        console.error(`Error checking URL ${url}:`, error);
      }
      return null;
    };

    const setAvailableURL = async () => {
      for (const url of urls) {
        const result = await checkURL(url);
        if (result) {
          return result;
        }
      }
      return null;
    };

    const availableURL = await setAvailableURL(); 
    if (availableURL) {
      setCurrentURL(availableURL);
    } else {
      console.error("No available URL found for the device.");
    }
    setCurrentDevice("ekyc/iris");
  };

  const handleOtpClick = () => {
    setIsOTPChecked(true);
    setIsIrisChecked(false);
    setIsFingerprintChecked(false);
  };
  const handleYes = async () => {    
    const cleanedAadhaarNo = aadhaarNumber.replace(/\s/g, '');
    const currentDate = new Date().toISOString().split('T')[0].replace(/-/g, '');
    const key = currentDate + ip;
    const encryptedAadhaarNo = encodeToBase64(cleanedAadhaarNo, key);
    if (isOTPChecked) {
      let response;
      if (window.location.href.includes("localhost")) {
        response = axios.post(Apiurl + 'sendotp')
          .then(response => {
            const { trns_code } = response.data;
            setTrnsCode(trns_code);
            console.log('API Response:', response.data);
          })
          .catch(error => {
            console.error('Error sending OTP:', error);
          });
      } else {
        response = axios.post(Apiurl + 'sendotp', {
          uid: encryptedAadhaarNo
        })
          .then(response => {
            const { trns_code } = response.data;
            setTrnsCode(trns_code);
            console.log('API Response:', response.data);
          })
          .catch(error => {
            console.error('Error sending OTP:', error);
          });
      }
      closeModal();
      setOtpModalOpen(true);
    }
    else {
      const MethodCapture = 'rd/capture';
      try {
        const response = await axios({
          method: 'CAPTURE',
          url: currentURL + MethodCapture,
          data: currentXML,
          headers: {
            'Content-Type': 'text/xml; charset=utf-8',
          },
        });
        const data = response.data;
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(data, "text/xml");
        const respNode = xmlDoc.getElementsByTagName("Resp")[0];

        if (respNode) {
          const errCode = respNode.getAttribute("errCode");
          const errInfo = respNode.getAttribute("errInfo");

          if (errCode !== "0") {
            alert("BioMetric Device Not Connected");
            console.log(`Error Code: ${errCode}, Error Info: ${errInfo}`);
            return;
          }
        }
        const deviceInfoNode = xmlDoc.getElementsByTagName("DeviceInfo")[0];
        const additionalInfoNode = deviceInfoNode.getElementsByTagName("additional_info")[0];
        const params = additionalInfoNode.getElementsByTagName("Param");
        let srno = null;

        for (let i = 0; i < params.length; i++) {
          if (params[i].getAttribute("name") === "srno") {
            srno = params[i].getAttribute("value");
            break;
          }
        }

        if (!srno) {
          throw new Error("srno not found in the response.");
        }
        const dataupdate = btoa(data);
        await axios.post(Apiurl + currentDevice + "/" + encryptedAadhaarNo + '/0/' + srno, dataupdate)
          .then(response => {
            console.log('Response:', response);
            if (response.status === 200) {
              console.log(' ekyc API Response:', response.data);
              if (response.data.errdesc) {
                alert(response.data.errdesc);
                closeModal();
              } else {
                const photo = atob(response.data.Pht);
                const username = response.data.Poi.name;
                setUsername(username);
                let dob = response.data.Poi.dob;
                if (dob.includes('-')) {
                    dob = dob.split('-')[2];
                }
                dob = parseInt(dob, 10);
                const currentYear = new Date().getFullYear();
                const dobyear = (currentYear - dob);
                setDobyear(dobyear);
                const maskedAadhaar = selectedUser.uuid ? `XXXX XXXX ${`${selectedUser.uuid}`.slice(-4)}` : '';

                setMaskedaadhaar(maskedAadhaar);
                const gender = response.data.Poi.gender === 'M' ? 'Male' : response.data.Poi.gender === 'F' ? 'Female' : 'Transgender';
                setGender(gender);
                if (photo && photo.length) {
                  let arrayBuffer = new Uint8Array(photo.length);
                  for (let i = 0; i < photo.length; i++) {
                    arrayBuffer[i] = photo.charCodeAt(i);
                  }

                  const blob = new Blob([arrayBuffer], { type: 'image/jpeg' });
                  const imageUrl = URL.createObjectURL(blob);
                  setImageUrl(imageUrl);
                }
                closeModal();
                setSuccessModalOpen(true);
                setTimeout(() => {
                  setSuccessModalOpen(false);
                }, 2000);
              }
            }
          })
          .catch(error => {
            console.error('Error:', error);
          });


      } catch (error) {
        console.error("Error capturing:", error);
      }
    }
  };

  const handlePerformEkycClick = (item) => {
    setSelectedUser(item);
    setAadhaarNumber(item.uuid);
    openModal();
  };
  const handlePerformekyc = (item) => {
    setAadhaarNumber(item);
    openModal();
  };
  const pendingCount = summaryData.Total - summaryData.Completed;
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <div className="dashboard-container">
        <div className="aadhaar-input-container" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <div style={{ display: 'flex', alignItems: 'center', marginRight: '1rem' }}>
            <label htmlFor="aadhaar" style={{ marginRight: '1rem' }}>Enter Aadhaar Number:</label>
            <input
              type="text"
              id="aadhaar"
              style={{ marginRight: '1rem' }}
              maxLength={19}
              value={aadhaarNo}
              error={!!aadhaarError}
              onChange={(e) => {
                let value = e.target.value;
                value = formatAadhaarNumber(value);
                setAadhaarNo(value);

                const plainValue = value.replace(/\s/g, '');
                const isAadhaar = plainValue.length === 12 && /^[2-9]{1}[0-9]{11}$/.test(plainValue);
                const isVid = plainValue.length === 16 && /^[0-9]{16}$/.test(plainValue);

                if ((isAadhaar && validateAadhaarNumber(plainValue)) || isVid) {
                  setAadhaarError('');
                } else {
                  setAadhaarError(translations[language].invalidAadhaar);
                }
              }}
            />
            
          <MDButton
          variant="contained"
          onClick={() => handlePerformekyc(aadhaarNo.replace(/\s/g, ''))}
          style={{ textTransform: 'none', backgroundColor: 'green', color: '#ffffff' }}
          disabled={!!aadhaarError || !(aadhaarNo && (isFingerprintChecked || isIrisChecked || isFaceChecked || isOTPChecked))}
        >
          PERFORM eKYC
        </MDButton>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', flex: 1, marginRight: '1rem', justifyContent: 'end' }}>
            <label htmlFor="verificationMethod" style={{ marginRight: '1rem' }}>Method:</label>
            <select
              id="verificationMethod"
              name="verificationMethod"
              value={isFingerprintChecked ? 'fingerprint' : isIrisChecked ? 'iris' : isFaceChecked ? 'face' : isOTPChecked ? 'otp' : ''}
              onChange={(e) => {
                switch (e.target.value) {
                  case 'fingerprint':
                    handleFingerprintClick();
                    setIsFingerprintChecked(true);
                    setIsIrisChecked(false);
                    setIsFaceChecked(false);
                    setIsOTPChecked(false);
                    break;
                  case 'iris':
                    handleIrisClick();
                    setIsIrisChecked(true);
                    setIsFingerprintChecked(false);
                    setIsFaceChecked(false);
                    setIsOTPChecked(false);
                    break;
                  case 'face':
                    setIsFaceChecked(true);
                    setIsFingerprintChecked(false);
                    setIsIrisChecked(false);
                    setIsOTPChecked(false);
                    break;
                  case 'otp':
                    handleOtpClick();
                    setIsOTPChecked(true);
                    setIsFingerprintChecked(false);
                    setIsIrisChecked(false);
                    setIsFaceChecked(false);
                    break;
                  default:
                    break;
                }
              }}
            >
              <option value="fingerprint">FINGERPRINT</option>
              <option value="iris">IRIS</option>
              {/* <option value="face">Face Capture</option>
                <option value="otp">OTP</option> */}
            </select>
          </div>
        </div>
        <MDTypography variant="caption" style={{ color: "red" }}>
          {aadhaarError}
        </MDTypography>
        <div className="allocated-data-container">
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
            <h2 >eKYC Completed List</h2>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <b style={{ margin: '0 10px' }}>Total: {summaryData.Total}</b>
              <b style={{ margin: '0 10px' }}>Completed: {summaryData.Completed}</b>
              <b style={{ margin: '0 10px' }}>Failed: {summaryData.Failed}</b>
              <b style={{ margin: '0 10px' }}>Pending: {pendingCount}</b>
            </div>
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              margin="normal"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          <DataTable
            columns={columns}
            data={filteredData}
            onSort={handleSort}
            pagination
            highlightOnHover
            striped
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 25, 50, 100]}
            className="dataTable"
            noDataComponent="No records found"
          />
        </div>

      </div>
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="ekyc-modal-title"
        aria-describedby="ekyc-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            height: '60%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            overflowY: 'scroll'
          }}
        >
          <IconButton onClick={closeModal} sx={{ position: 'absolute', top: 0, right: 0 }}>
            <CloseIcon />
          </IconButton>
          <Typography id="ekyc-modal-title" variant="h2" component="h2" sx={{ mt: 2, textAlign: 'center' }}>
            Consent for eKYC
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2 }}>

            {/* {selectedUser && (
              <Typography id="ekyc-modal-description">
                <p><b>Name : </b>{selectedUser.name}</p>
                <p><b>Aadhaar No : </b>{selectedUser.uuid ? (String(selectedUser.uuid) ? `${'X'.repeat(String(selectedUser.uuid).length - 4)}${String(selectedUser.uuid).slice(-4)}` : '') : selectedUser.uuid}</p>
                <p><b>DOB : </b>{selectedUser.dob}</p>
                <p><b>Gender : </b>{selectedUser.gender === 'M' ? 'Male' : selectedUser.gender === 'F' ? 'Female' : 'Transgender'}</p>
              </Typography>

            )} */}
          </Box>
          <MDBox mb={2}>
            <label>
              <input
                type="checkbox"
                checked={isConsentGiven}
                style={{ width: '25px', height: '20px' }}
                onChange={(e) => {
                  setIsConsentGiven(e.target.checked);
                }}
              />
              {translations[language].consentText}
            </label>
          </MDBox>


          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button variant="contained" color="primary" style={{ color: 'white' }} onClick={handleYes} disabled={!isConsentGiven}>
              Continue
            </Button>
          </Box>
        </Box>
      </Modal>
      {selectedUser && (
        <Modal
          open={successModalOpen}
          onClose={handleCloseVerify}
          aria-labelledby="success-modal-title"
          aria-describedby="success-modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 600,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              borderRadius: 1,
            }}
          >
            <IconButton
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
              }}
              onClick={handleCloseVerify}
            >
              <CloseIcon />
            </IconButton>
            <Typography id="success-modal-title" variant="h6" component="h2">
              Successfully Verified
            </Typography>
            <Typography id="success-modal-description" sx={{ mt: 2 }}>
              Your details have been successfully verified.
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2 }}>
              <Box>
                <p><b>Name : </b>{username}</p>
                <p><b>Aadhaar No : </b>{maskedaadhaar}</p>
                <p><b>DOB : </b>{dobyear}</p>
                <p><b>Gender : </b>{gender}</p>
              </Box>
              <img src={imageUrl} alt="Profile Picture" style={{ width: '150px', height: '150px', borderRadius: '50%', objectFit: 'fill' }} />
            </Box>
          </Box>
        </Modal>
      )}
      {selectedUser && (
        <OTPModal
          open={otpModalOpen}
          handleClose={handleCloseOTPModal}
          aadhaarNo={aadhaarNumber}
          trnsCode={trnsCode}
        />
      )}
    </DashboardLayout>
  );
}

export default Dashboard;
